import {
  Button,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
} from "@mui/material";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";
import { fetch } from "../../../../Utils/fetch";
import { APIURLs } from "../../../../Urls";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Map as immuMap } from "immutable";
import DetailDialog from "./DetailDialog";
import AddIcon from "@mui/icons-material/Add";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
}));

function StaffListPage({ children: _submitComponent, onEnd }) {
  const histroy = useHistory();
  const [loading, setLoad] = useState(true);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState(immuMap({ next: undefined, results: [] }));

  const [selectedClient, setSelectedClient] = useState(false);

  const _dataList = useMemo(() => data.get("results"), [data]);
  const _hasNext = useMemo(() => {
    const a = data.get("next");
    console.log(a);
    return a;
  }, [data]);

  const isItemLoaded = useCallback((index) => !!_dataList[index], [_dataList]);
  const Row = useMemo(
    () =>
      memo(
        function Row({ index, style }) {
          const data = _dataList[index];
          return (
            <ListItemButton
              style={style}
              onClick={handleOpenDetailDialog}
              data-idx={index}
            >
              <ListItemText primary={data.username} />
            </ListItemButton>
          );
        },
        () => true
      ),
    [_dataList]
  );

  function handleOpenDetailDialog(e) {
    e.preventDefault();
    e.stopPropagation();
    const { idx = undefined } = e.currentTarget.dataset ?? {};

    setSelectedClient(idx === undefined ? {} : _dataList[idx]);
  }
  function handleCloseDetailDialog() {
    setSelectedClient(undefined);
  }
  function handleLoadmore() {
    if (_hasNext)
      fetch({ url: data.get("next"), method: "post", auth: true })
        .then((resp) => {
          const data = resp.data;
          setData((v) =>
            v
              .set("next", data.next)
              .update("results", (_arr) => _arr.concat(data.results ?? []))
          );
        })
        .catch()
        .finally(() => setLoadedData(true));
  }

  useEffect(() => {
    fetch(APIURLs.staff.list)
      .then((resp) => {
        const data = resp.data;
        setData((v) =>
          v
            .set("next", data.next)
            .update("results", (_arr) => _arr.concat(data.results ?? []))
        );
      })
      .catch()
      .finally(() => setLoadedData(true));
  }, []);

  return (
    <Fragment>
      <StyledPaper>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={_dataList.length + (_hasNext ? 1 : 0)}
              loadMoreItems={handleLoadmore}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={_dataList.length}
                  itemSize={48}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </StyledPaper>
      <form onSubmit={handleOpenDetailDialog} style={{ display: "none" }}>
        {_submitComponent}
      </form>

      <DetailDialog
        data={selectedClient}
        onClose={handleCloseDetailDialog}
        onEnd={onEnd}
      />
    </Fragment>
  );
}

export default StaffListPage;
