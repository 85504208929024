import { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { fetch } from "../../Utils/fetch";
import { APIURLs } from "../../Urls";
import DashboardPage from "../Dashboard";
import WizradPage from "../Wizard";
import { styled, LinearProgress } from "@mui/material";

const StyledProgressbarWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  inset: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function RootPage() {
  const history = useHistory();
  const [status, setStatus] = useState(undefined);
  useEffect(() => {
    fetch(APIURLs.vars("get", { key: "startlog" }))
      .then((resp) => {
        const res = !!resp.data.value;
        if (res === false) history.replace("/wizard");
        setStatus(res);
      })
      .catch(() => setStatus(-1));
  }, []);

  return status === undefined ? (
    <StyledProgressbarWrapper>
      <LinearProgress />
    </StyledProgressbarWrapper>
  ) : (
    <Switch>
      <Route exact path="/wizard" component={WizradPage} />
      <Route path="/dashboard/:subpage?" component={DashboardPage} />
    </Switch>
  );
}

export default RootPage;
