import {
  Button,
  List,
  ListItem,
  ListSubheader,
  Paper,
  TextField,
  collapseClasses,
  listItemButtonClasses,
  styled,
} from "@mui/material";
import {
  Fragment,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { APIURLs } from "../../Urls";
import { fetch } from "../../Utils/fetch";
import { usePageStore } from ".";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: "visible",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${collapseClasses.wrapperInner}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

function SetApartmentInfoPage({
  isWizard = false,
  sx,
  children: _submitComponent,
  onEnd,
}) {
  const [loading, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const ref = useRef();
  const submitComponent = useMemo(() => {
    if (!!_submitComponent)
      return cloneElement(_submitComponent, { onClick: handleClickImport });
    return;
  }, [_submitComponent]);

  function handleOnSubmit(e) {
    e.preventDefault();
  }

  function handleClickImport(e) {
    e.preventDefault();
    setLoad(true);
    try {
      const data = [...new FormData(ref.current)];
      const value = data.reduce((chain, current) => {
        let [building, key] = current[0].split(".");
        chain[building] = {
          ...chain[building],
          [key]:
            key === "building_password"
              ? current[1].split(",").map((i) => i.trim())
              : current[1],
        };
        return chain;
      }, {});
      fetch(APIURLs.vars("post"), { key: "building_info", value })
        .then(() => {
          if (isWizard) usePageStore.getState().setNext();
          else onEnd(false);
        })
        .catch((err) => {
          if (!isWizard) onEnd(true);
        });
    } catch {
      setLoad(false);
      if (!isWizard) onEnd(true);
    }
  }

  useEffect(() => {
    // setLoad(true);
    fetch(APIURLs.vars("get", { key: "building_info" }))
      .then((resp) => {
        if (resp.data !== null) setData(Object.entries(resp.data.value));
      })
      .finally(() => {
        // setLoad(false);
      });
  }, []);

  return (
    <Fragment>
      <StyledPaper sx={sx}>
        <List component="form" onSubmit={handleOnSubmit} ref={ref}>
          {data !== null &&
            data.length > 0 &&
            data.map(([k, v]) => (
              <Fragment>
                <ListSubheader disableSticky>
                  {k.startsWith("0-") ? `${k} [테스트용]` : k}
                </ListSubheader>
                <ListItem>
                  <TextField
                    label="비밀번호"
                    defaultValue={v.building_password}
                    name={`${k}.building_password`}
                    disabled={loading}
                  />
                  <TextField
                    label="고객센터 번호"
                    defaultValue={v.contact}
                    name={`${k}.contact`}
                    group
                    disabled={loading}
                  />
                </ListItem>
              </Fragment>
            ))}
        </List>
      </StyledPaper>
      {isWizard ? (
        <div className="action">
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: "grey.400" }}
            disabled
          >
            Prev
          </Button>
          <Button variant="contained" onClick={handleClickImport}>
            Next
          </Button>
        </div>
      ) : (
        submitComponent
      )}
    </Fragment>
  );
}

export default SetApartmentInfoPage;
