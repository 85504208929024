const url_prefix =
  process.env.NODE_ENV === "production"
    ? "/api/staff"
    : "https://localhost:8000/api/staff";
export const pure_prefix =
  process.env.NODE_ENV === "production" ? "" : "https://localhost:8000";
// const url_prefix = "/api/staff";

function _obj2queries(dataset) {
  return dataset
    ? Object.entries(dataset).reduce(
        (queries, [k, v], i) =>
          v ?? false
            ? queries !== ""
              ? `${queries}&${k}=${v}`
              : `?${k}=${v}`
            : queries,
        ""
      )
    : "";
}

export const APIURLs = {
  system: {
    chk_ready: {
      url: `${url_prefix}/chk_ready/`,
      method: "get",
      auth: false,
      withCredentials: false,
      gotoLoginPage: false,
    },
  },
  auth: {
    check_is_ready: {
      url: `${url_prefix}/create/`,
      method: "get",
      auth: false,
      withCredentials: false,
      gotoLoginPage: false,
    },
    create_super_user: {
      url: `${url_prefix}/create/`,
      method: "post",
      auth: false,
      withCredentials: false,
      gotoLoginPage: false,
    },
    login: {
      url: `${url_prefix}/login/`,
      method: "post",
      auth: false,
      withCredentials: false,
      gotoLoginPage: false,
    },
  },
  vars: (method, queries) => ({
    url: `${url_prefix}/vars/${queries ? _obj2queries(queries) : ""}`,
    method,
    auth: true,
    withCredentials: true,
  }),
  clients: {
    upload: {
      url: `${url_prefix}/init/`,
      method: "post",
      auth: true,
      withCredentials: true,
    },
    search: (queries) => ({
      url: `${url_prefix}/client/${queries ? _obj2queries(queries) : ""}`,
      method: "get",
      auth: true,
      withCredentials: true,
    }),
    create: {
      url: `${url_prefix}/client/`,
      method: "post",
      auth: true,
      withCredentials: true,
    },
    update: {
      url: `${url_prefix}/client/`,
      method: "patch",
      auth: true,
      withCredentials: true,
    },
    delete: (id) => ({
      url: `${url_prefix}/client/?id=${id}`,
      method: "delete",
      auth: true,
      withCredentials: true,
    }),
    export: {
      url: `${url_prefix}/client/export/`,
      method: "get",
      auth: true,
      withCredentials: true,
    },
    getPWD: {
      url: `${url_prefix}/client/pwd/`,
      method: "post",
      auth: true,
      withCredentials: true,
    },
  },
  files: {
    upload: {
      url: `${url_prefix}/files/`,
      method: "post",
      auth: true,
      withCredentials: true,
      contentType: "multipart/form-data",
    },
    get: (filename) => ({
      url: `${pure_prefix}/public/${filename}`,
      method: "get",
    }),
  },
  visitors: {
    count: {
      url: `${url_prefix}/visitor/count/`,
      method: "get",
      auth: true,
      withCredentials: true,
    },
    export: {
      url: `${url_prefix}/visitor/export/`,
      method: "get",
      auth: true,
      withCredentials: true,
    },
  },
  staff: {
    list: {
      url: `${url_prefix}/grant/`,
      method: "get",
      auth: true,
      withCredentials: true,
    },
    create: {
      url: `${url_prefix}/grant/`,
      method: "post",
      auth: true,
      withCredentials: true,
    },
    update: {
      url: `${url_prefix}/grant/`,
      method: "patch",
      auth: true,
      withCredentials: true,
    },
    delete: (username) => ({
      url: `${url_prefix}/grant/?username=${username}`,
      method: "delete",
      auth: true,
      withCredentials: true,
    }),
  },
  manager: {
    list: {
      url: `${url_prefix}/visitor/st_list/`,
      method: "get",
      auth: true,
      withCredentials: true,
    },
    detail: {
      url: `${url_prefix}/visitor/st_detail/`,
      method: "post",
      auth: true,
      withCredentials: true,
    },
    update: {
      url: `${url_prefix}/visitor/st_update/`,
      method: "patch",
      auth: true,
      withCredentials: true,
    },
    ws: { url: `${pure_prefix}/ws/` },
  },
};
