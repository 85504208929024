import {
  ListItemText,
  Paper,
  paperClasses,
  styled,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { Link, Switch, Route } from "react-router-dom";

import VisitorsPage from "./SubPages/Visitors";
import ClientsPage from "./SubPages/Clients";
import SettingsPage from "./SubPages/Settings";
import ManagerPage from "./SubPages/Manager";

import { useAuthStore } from "../../Store/authStore";
const StyledWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  [`& > div.header`]: {
    display: "flex",
    flexDirection: "row",
    [`& > .${paperClasses.root}`]: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      [`&.fit`]: {
        flex: 1,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`& > div.header`]: {
      flexDirection: "column",

      [` > .${paperClasses.root}`]: {
        flex: 1,
      },
    },
  },
}));
function DashboardPage() {
  const info = useAuthStore((state) => state.info);
  function handleClickLogout() {
    useAuthStore.getState().setInfo(undefined);
  }
  return (
    <StyledWrapper>
      <div className="header">
        <Paper>
          {/* <Typography variant="h4">Wizard</Typography> */}
          <ListItemText
            primary="Dashboard"
            secondary="ECOLE-서비스 관리 시스템"
          />
        </Paper>
        <Paper className="fit">
          <BottomNavigation
            showLabels
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
          >
            {(info.super || info.visitor <= 40) && (
              <BottomNavigationAction
                label="매니저"
                LinkComponent={Link}
                to="/dashboard/manager"
                icon={<BadgeOutlinedIcon />}
              />
            )}
            {(info.super || info.visitor <= 30) && (
              <BottomNavigationAction
                label="방문자"
                LinkComponent={Link}
                to="/dashboard/visitor"
                icon={<TransferWithinAStationIcon />}
              />
            )}
            {(info.super || info.contractor <= 30) && (
              <BottomNavigationAction
                label="계약자 검색"
                LinkComponent={Link}
                to="/dashboard/client"
                icon={<PersonSearchIcon />}
              />
            )}
            {info.super && (
              <BottomNavigationAction
                label="설정"
                icon={<SettingsIcon />}
                LinkComponent={Link}
                to="/dashboard/settings"
              />
            )}
            <BottomNavigationAction
              label="로그아웃"
              icon={<LogoutIcon />}
              onClick={handleClickLogout}
              // LinkComponent={Link}
              // to="/dashboard/settings"
            />
          </BottomNavigation>
        </Paper>
      </div>
      <Switch>
        <Route exact path="/dashboard/manager" component={ManagerPage} />
        <Route exact path="/dashboard/visitor" component={VisitorsPage} />
        <Route exact path="/dashboard/client" component={ClientsPage} />
        <Route path="/dashboard/settings/:subpage?" component={SettingsPage} />
      </Switch>
    </StyledWrapper>
  );
}
export default DashboardPage;
