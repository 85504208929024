import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuthStore = create(
  persist(
    (set) => ({
      info: undefined,
      setInfo: (v) => set({ info: v }),
    }),
    { name: "__auth__" }
  )
);
