import { Button, Paper, styled } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { fetch } from "../../../../Utils/fetch";
import { APIURLs } from "../../../../Urls";
import VisitCard from "./VisitCard";
import DownloadIcon from "@mui/icons-material/Download";
import { exportXLSX } from "../../../../Utils/xlsx";

const StyledPaper = styled("div")(({ theme }) => ({
  //   flex: 1,
  display: "flex",
  flexWrap: "wrap",
  overflow: "visible",
  //   margin: theme.spacing(1),
  //   padding: theme.spacing(2),
}));

function VisitorsPage() {
  const histroy = useHistory();
  const [loading, setLoad] = useState(true);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState([]);

  function handleClickExport() {
    fetch(APIURLs.visitors.export)
      .then((resp) => {
        const _data = resp.data;
        const result = new Array(_data.length + 1);
        result[0] = ["동", "호", "이름", "전화번호", "방문시간", "초방/재방"];
        let _item;
        for (let i = 0; i < result.length - 1; i++) {
          _item = _data[i];
          // console.log(_item);
          result[i + 1] = [
            _item.building,
            _item.room,
            _item.name,
            _item.phone,
            _item.created_date,
            _item.visit_type ? "초방" : "재방",
          ];
        }
        return exportXLSX(result);
      })
      .then((blob) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.xlsx";
        link.click();
        link.remove();
      });
  }

  useEffect(() => {
    fetch(APIURLs.visitors.count)
      .then((resp) => {
        let { client_group, visit_count } = resp.data ?? {};

        if (!Array.isArray(client_group)) client_group = [client_group];
        if (!Array.isArray(visit_count)) visit_count = [visit_count];
        visit_count = Object.fromEntries(
          visit_count.map(({ building, ...data }) => [building, data])
        );

        let all_clients = 0,
          all_total_first_visit = 0,
          all_total_re_visit = 0,
          all_total_re_visit_num = 0,
          all_today_first_visit = 0,
          all_today_re_visit = 0;

        const _result = client_group
          .map(({ building, cnt }) => {
            const {
              total_first_visit = 0,
              total_re_visit = 0,
              total_re_visit_num = 0,
              today_first_visit = 0,
              today_re_visit = 0,
            } = visit_count[building] ?? {};

            all_clients += cnt;
            all_total_first_visit += total_first_visit;
            all_total_re_visit += total_re_visit;
            all_total_re_visit_num += total_re_visit_num;
            all_today_first_visit += today_first_visit;
            all_today_re_visit += today_re_visit;

            return [
              building,
              {
                total_first_visit,
                total_re_visit,
                total_re_visit_num,
                today_first_visit,
                today_re_visit,
                t: cnt,
              },
            ];
          })
          .filter(([k]) => Number(k));
        _result.unshift([
          "전체",
          {
            total_first_visit: all_total_first_visit,
            total_re_visit: all_total_re_visit,
            total_re_visit_num: all_total_re_visit_num,
            today_first_visit: all_today_first_visit,
            today_re_visit: all_today_re_visit,
            t: all_clients,
          },
        ]);
        setData(_result);
      })
      .catch()
      .finally(() => setLoadedData(true));
  }, []);

  return (
    <Fragment>
      <Paper
        sx={{
          margin: "0 8px",
          p: 1,
          display: "inline-flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          endIcon={<DownloadIcon />}
          onClick={handleClickExport}
        >
          XLSX
        </Button>
      </Paper>
      <StyledPaper>
        {data.map(([k, v]) => (
          <VisitCard title={k} data={v} />
        ))}
      </StyledPaper>
      {/* <div style={{ }}>
        <Button
          variant="contained"
          // sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          다운로드
        </Button>
      </div> */}
    </Fragment>
  );
}

export default VisitorsPage;
