import {
  Button,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  collapseClasses,
  listItemButtonClasses,
  styled,
} from "@mui/material";
import {
  Fragment,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Map as immuMap } from "immutable";
import { APIURLs, pure_prefix } from "../../Urls";
import { fetch } from "../../Utils/fetch";
import ImageButton from "../../Components/ImageButton";
import axios from "axios";
import { caution } from "../../Templates/caution";
import { pwd } from "../../Templates/pwd";
import { usePageStore } from ".";
import { GuideMapSubForm } from "./SetUIGuideMap";
import { GuideAppSubForm } from "./SetUIGuideApp";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: "visible",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${collapseClasses.wrapperInner}`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& textarea`]: {
    backgroundColor: theme.palette.grey[100],
    outline: "0px transparent !important",
    color: "#fff",
  },
}));

function SetUIPage({
  isWizard = false,
  sx,
  children: _submitComponent,
  onEnd,
}) {
  const [loading, setLoad] = useState(true);
  const [openIdx, setOpenIdx] = useState(0);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState(
    immuMap({
      cautionHTML: caution,
      pwdHTML: pwd,
      css: {},
      map: {},
      app: {},
      web: {},
    })
  );
  const { cautionTemplate, pwdTemplate, css, map, app, web } = useMemo(
    () => ({
      cautionTemplate: data.get("cautionHTML"),
      pwdTemplate: data.get("pwdHTML"),
      css: data.get("css"),
      map: data.get("map"),
      app: data.get("app"),
      web: data.get("web"),
    }),
    [data]
  );
  const submitComponent = useMemo(() => {
    if (!!_submitComponent)
      return cloneElement(_submitComponent, { onClick: handleClickImport });
    return;
  }, [_submitComponent]);
  // const textEncoder = useMemo(() => {
  //   return new TextEncoder();
  // }, []);
  const ref = useRef();

  function handleClickImport(e) {
    e.preventDefault();
    setLoad(true);
    try {
      const {
        logo_img,
        guide_map_image,
        guide_app_image,
        web_global_favicon,
        web_global_map_active,
        cautionTemplate,
        pwdTemplate,
        ...data
      } = Object.fromEntries(new FormData(ref.current));

      const color_data = Object.fromEntries(
        Object.entries(data)
          .filter(([k, v]) => k.startsWith("color-"))
          .map(([k, v]) => [k.replace("color-", ""), v])
      );
      const guidemap_data = Object.fromEntries(
        Object.entries(data)
          .filter(([k, v]) => k.startsWith("guide_map-"))
          .map(([k, v]) => [k.replace("guide_map-", ""), v])
      );
      const guideapp_data = Object.fromEntries(
        Object.entries(data)
          .filter(([k, v]) => k.startsWith("guide_app-"))
          .map(([k, v]) => [k.replace("guide_app-", ""), v])
      );
      const web_data = Object.fromEntries(
        Object.entries(data)
          .filter(([k, v]) => k.startsWith("web_global-"))
          .map(([k, v]) => [k.replace("web_global-", ""), v])
      );
      web_data["map_active"] = web_global_map_active === "on";
      guidemap_data["map_active"] = web_global_map_active === "on";

      Promise.all([
        (logo_img?.size ?? 0) === 0
          ? Promise.resolve()
          : (async function () {
              const form = new FormData();
              form.append(
                "file",
                new File([await logo_img.arrayBuffer()], `logo`)
              );
              return form;
            })().then((_form) => {
              fetch(APIURLs.files.upload, _form);
            }),
        (guide_map_image?.size ?? 0) === 0
          ? Promise.resolve()
          : (async function () {
              const form = new FormData();
              form.append(
                "file",
                new File([await guide_map_image.arrayBuffer()], `guide`)
              );
              return form;
            })().then((_form) => {
              fetch(APIURLs.files.upload, _form);
            }),
        (guide_app_image?.size ?? 0) === 0
          ? Promise.resolve()
          : (async function () {
              const form = new FormData();
              form.append(
                "file",
                new File([await guide_app_image.arrayBuffer()], `manual`)
              );
              return form;
            })().then((_form) => {
              fetch(APIURLs.files.upload, _form);
            }),
        (web_global_favicon?.size ?? 0) === 0
          ? Promise.resolve()
          : (async function () {
              const form = new FormData();
              form.append(
                "file",
                new File([await web_global_favicon.arrayBuffer()], `favicon`)
              );
              return form;
            })().then((_form) => {
              fetch(APIURLs.files.upload, _form);
            }),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append(
              "file",
              new File([JSON.stringify(color_data)], "css.json")
            );
            return form;
          })()
        ),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append("file", new File([cautionTemplate], "caution.html"));
            return form;
          })()
        ),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append("file", new File([pwdTemplate], "pwd.html"));
            return form;
          })()
        ),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append(
              "file",
              new File([JSON.stringify(guidemap_data)], "guide_map.json")
            );
            return form;
          })()
        ),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append(
              "file",
              new File([JSON.stringify(guideapp_data)], "guide_app.json")
            );
            return form;
          })()
        ),
        fetch(
          APIURLs.files.upload,
          (function () {
            const form = new FormData();
            // const blob = new Blob(, {
            //   type: "text/json",
            // });

            form.append(
              "file",
              new File([JSON.stringify(web_data)], "web.json")
            );
            return form;
          })()
        ),
      ])
        .then(() => {
          if (isWizard) usePageStore.getState().setNext();
          else onEnd(false);
        })
        .catch(() => {
          setLoad(false);
          if (!isWizard) onEnd(true);
        });
    } catch (e) {
      console.error(e);
      setLoad(false);
      if (!isWizard) onEnd(true);
    }
  }

  function handleClickListSubHeader(e) {
    const { idx } = e.currentTarget.dataset ?? {};
    console.log(e.currentTarget.dataset);
    if (idx === undefined) return;
    setOpenIdx(Number(idx));
  }

  useEffect(() => {
    Promise.allSettled([
      !isWizard
        ? axios
            .get(`${pure_prefix}/public/caution.html?ts=${Date.now()}`)
            .then((resp) => setData((v) => v.set("cautionHTML", resp.data)))
            .catch(() => {})
        : setData((v) => v.set("cautionHTML", caution)),
      !isWizard
        ? axios
            .get(`${pure_prefix}/public/pwd.html?ts=${Date.now()}`)
            .then((resp) => setData((v) => v.set("pwdHTML", resp.data)))
            .catch()
        : setData((v) => v.set("pwdHTML", pwd)),
      axios
        .get(`${pure_prefix}/public/css.json?ts=${Date.now()}`)
        .then((resp) => setData((v) => v.set("css", resp.data)))
        .catch(),
      axios
        .get(`${pure_prefix}/public/guide_map.json?ts=${Date.now()}`)
        .then((resp) => setData((v) => v.set("map", resp.data)))
        .catch(),
      axios
        .get(`${pure_prefix}/public/guide_app.json?ts=${Date.now()}`)
        .then((resp) => setData((v) => v.set("app", resp.data)))
        .catch(),
      axios
        .get(`${pure_prefix}/public/web.json?ts=${Date.now()}`)
        .then((resp) => setData((v) => v.set("web", resp.data)))
        .catch(),
    ]).finally(() => setLoadedData(true));
  }, []);

  return (
    <Fragment>
      <StyledPaper sx={sx}>
        <List
          dense
          disablePadding
          component="form"
          ref={ref}
          onSubmit={(e) => e.preventDefault()}
        >
          {loadedData ? (
            <Fragment>
              {/**파비콘 타이틀 */}
              <ListItemButton
                onClick={handleClickListSubHeader}
                data-idx={0}
                sx={{ borderRadius: "14px 14px 0 0" }}
              >
                1. 색 및 이미지
              </ListItemButton>
              <Collapse in={openIdx === 0}>
                <Fragment>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      로고
                    </Typography>
                    <ImageButton
                      name="logo_img"
                      src={APIURLs.files.get("logo").url}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      전체 모드
                    </Typography>
                    <Select name="color-mode" defaultValue={css.mode ?? "dark"}>
                      <MenuItem value={"dark"}>어두움</MenuItem>
                      <MenuItem value={"light"}>밝음</MenuItem>
                    </Select>
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      배경 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-bg_color"
                      name="color-bg_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.bg_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                  <ListItem component="div">
                    {/* <ListItemText primary="메인컬러" /> */}
                    <Typography variant="button" sx={{ pr: 2 }}>
                      버튼 배경 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-button_bg_color"
                      name="color-button_bg_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.button_bg_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      버튼 폰트 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-button_text_color"
                      name="color-button_text_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.button_text_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      기본 폰트 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-text_color"
                      name="color-text_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.text_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      내비게이션 버튼 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-nav_bg_color"
                      name="color-nav_bg_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.nav_bg_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      내비게이션 폰트 컬러
                    </Typography>
                    <input
                      type="color"
                      id="color-nav_text_color"
                      name="color-nav_text_color"
                      // ref={imguploadRef}
                      style={{
                        width: 40,
                        height: 40,
                        padding: 0,
                        border: "unset",
                        cursor: "pointer",
                      }}
                      defaultValue={css.nav_text_color}
                      // onChange={handleOnPatchChangeImg}
                    />
                  </ListItem>
                </Fragment>
              </Collapse>
              <ListItemButton onClick={handleClickListSubHeader} data-idx={1}>
                2. 유의사항 템플릿
              </ListItemButton>
              <Collapse in={openIdx === 1}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="Minimum 3 rows"
                  style={{ width: "100%", maxWidth: 568 }}
                  defaultValue={cautionTemplate}
                  name="cautionTemplate"
                />
              </Collapse>
              <ListItemButton onClick={handleClickListSubHeader} data-idx={2}>
                3. 세대 비밀번호 템플릿
              </ListItemButton>
              <Collapse in={openIdx === 2}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="Minimum 3 rows"
                  style={{ width: "100%", maxWidth: 568 }}
                  defaultValue={pwdTemplate}
                  name="pwdTemplate"
                />
              </Collapse>
              <ListItemButton onClick={handleClickListSubHeader} data-idx={3}>
                4. 가이드 맵 페이지
              </ListItemButton>
              <Collapse in={openIdx === 3}>
                <GuideMapSubForm data={map} />
              </Collapse>
              <ListItemButton onClick={handleClickListSubHeader} data-idx={4}>
                5. 하자점검 가이드 페이지
              </ListItemButton>
              <Collapse in={openIdx === 4}>
                <GuideAppSubForm data={app} />
              </Collapse>
              <ListItemButton onClick={handleClickListSubHeader} data-idx={5}>
                6. 웹 타이틀 및 favicon
              </ListItemButton>
              <Collapse in={openIdx === 5}>
                <Fragment>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      타이틀
                    </Typography>
                    <TextField
                      name="web_global-title"
                      defaultValue={web.title}
                    />
                  </ListItem>
                  <ListItem component="div">
                    <Typography variant="button" sx={{ pr: 2 }}>
                      Favicon
                    </Typography>
                    <ImageButton
                      name="web_global_favicon"
                      src={APIURLs.files.get("favicon").url}
                    />
                  </ListItem>
                </Fragment>
              </Collapse>
            </Fragment>
          ) : (
            <CircularProgress />
          )}
        </List>
      </StyledPaper>
      {isWizard ? (
        <div className="action">
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: "grey.400" }}
            disabled
          >
            Prev
          </Button>
          <Button variant="contained" onClick={handleClickImport}>
            Next
          </Button>
        </div>
      ) : (
        submitComponent
      )}
    </Fragment>
  );
}

export default SetUIPage;
