import {
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import ImageButton from "../../Components/ImageButton";
import { APIURLs } from "../../Urls";

export function GuideAppSubForm({
  data = { type: "image", size: "contained", downloadable: true },
}) {
  const [type, setType] = useState(data.type ?? "image");
  return (
    <Fragment>
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          타입
        </Typography>
        <Select
          name="guide_app-type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"image"}>이미지</MenuItem>
          <MenuItem value={"video"}>유튜브</MenuItem>
        </Select>
      </ListItem>
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          {type === "image" ? "이미지" : "Key"}
        </Typography>
        {type === "image" ? (
          <ImageButton
            name="guide_app_image"
            src={APIURLs.files.get("manual").url}
          />
        ) : (
          <TextField name="guide_app-src" defaultValue={data.src} />
        )}
      </ListItem>
      {type === "image" && (
        <Fragment>
          <ListItem component="div">
            <Typography variant="button" sx={{ pr: 2 }}>
              사이즈
            </Typography>
            <Select name="guide_app-size" defaultValue={data.size}>
              <MenuItem value={"contained"}>contained</MenuItem>
              <MenuItem value={"cover"}>cover</MenuItem>
            </Select>
          </ListItem>
        </Fragment>
      )}
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          앱 링크
        </Typography>
        <TextField name="guide_app-href" defaultValue={data.href} />
      </ListItem>
    </Fragment>
  );
}
