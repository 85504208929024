export const caution = `<nocheckbox>
<b>1. 세대 출입시</b> 제공되어지는 비밀번호는 계약자 본인만 사용하며 타인에게 유출되지 않도록 유의바랍니다.
(세대 파손, 도난 방지)
</nocheckbox>
<nocheckbox>
<b>2. 재방문 시</b> 반드시 재접속 후 비밀번호를 재발급 받으시기 바랍니다.
(행사 일자 별 비밀번호 변경됨)
</nocheckbox>
<nocheckbox>
<b>3. 동행가족분들은 반드시</b> 계약자분과 동행하여 입장하여 주시기 바랍니다.
</nocheckbox>`;
