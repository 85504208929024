import {
  Button,
  Dialog,
  Divider,
  TextField,
  buttonClasses,
  dividerClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { MSG_TYPE, useMSGStore } from "../../../../Store/msgStore";
import { fetch } from "../../../../Utils/fetch";
import LoadingButton from "../../../../Components/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { APIURLs } from "../../../../Urls";
import AddIcon from "@mui/icons-material/Add";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    padding: theme.spacing(1),
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 0),
    },
    [`& > form `]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 300,
      padding: theme.spacing(2),
      [`& > *`]: {
        marginBottom: theme.spacing(2),
      },
      [`& > div.pwd`]: {
        display: "inline-flex",
        [`& > .${buttonClasses.root}`]: {
          minWidth: "unset",
        },
      },
      [`& > .${buttonClasses.root}`]: {
        //   marginTop: theme.spacing(4),
      },
    },
    [`& > div.actions`]: {
      display: "inline-flex",
      justifyContent: "flex-end",
      [`& > .${buttonClasses.root}`]: {
        minWidth: "unset",
      },
    },
  },
}));

function DetailDialog({ data, onClose }) {
  const _buttonRef = useRef();
  const _inputRef = useRef();
  const _formRef = useRef();
  const _buildingRef = useRef();
  const _roomRef = useRef();
  const isAdd = useMemo(() => {
    return !data?.phone;
  }, [data]);
  const [isEdit, setEdit] = useState(false);
  const [addFields, setAddFields] = useState([]);
  //   const innerComponent = useMemo(()=>)
  function handleClickSubmit() {
    _buttonRef.current.click();
  }
  function handleClickEdit() {
    setEdit(true);
  }
  function handleCloseMSGDialog() {
    useMSGStore.getState().done();
  }
  function handleDelete() {
    fetch(APIURLs.clients.delete(data.id))
      .then(() => {
        useMSGStore.getState().done();
        onClose();
      })
      .catch(() => {
        useMSGStore
          .getState()
          .push(
            undefined,
            `서버 오류\n(재시도 해주십시오)`,
            undefined,
            MSG_TYPE.ERROR
          );
      });
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const __data = [...new FormData(e.target)];
    const _data = Object.fromEntries(
      __data.filter(
        ([k]) => !(k.startsWith("extra.") || k.startsWith("addedextra."))
      )
    );

    _data.password = _inputRef.current.value.split(",").map((i) => i.trim());
    _data.extra = {
      ...Object.fromEntries(
        __data
          .filter(([k]) => k.startsWith("extra."))
          .map(([k, v]) => [k.replace("extra.", ""), v.trim()])
      ),
      ...Object.fromEntries(
        __data
          .filter(([k, v]) => {
            const _v = v.trim();
            return (
              k.startsWith("addedextra.") && _v !== "" && _v.includes(":")
            );
          })
          .map(([_, v]) => v.split(":", 2).map((v) => v.trim()))
      ),
    };

    if (!isAdd && isEdit) {
      for (let [k, v] of Object.entries(_data)) {
        if (k === "extra") {
          const _cuExtra = data[k] ?? {};
          const cuExtra = Object.entries(_cuExtra);
          const moExtra = Object.entries(v);
          if (
            cuExtra.length === moExtra.length &&
            moExtra.map(([k, v]) => _cuExtra[k] === v).every((v) => v)
          ) {
            delete _data[k];
          }
          continue;
        }
        if (k === "password") {
          const cuPWD = data[k];
          if (
            cuPWD.length === v.length &&
            v.filter((_v) => cuPWD.indexOf(_v) === -1).length === 0
          )
            delete _data[k];
          continue;
        }
        if (data[k] === v) delete _data[k];
      }
      _data.id = data.id;
    }
    return fetch(
      !isAdd ? APIURLs.clients.update : APIURLs.clients.create,
      _data
    )
      .then((resp) => {
        onClose();
      })
      .catch((e) => {
        if (e.response.status === 400) {
          useMSGStore
            .getState()
            .push(
              undefined,
              `계약자가 이미 있거나 값입력이 잘못되었습니다.`,
              undefined,
              MSG_TYPE.ERROR
            );
          return;
        }
        useMSGStore
          .getState()
          .push(
            undefined,
            `서버 오류\n(재시도 해주십시오)`,
            undefined,
            MSG_TYPE.ERROR
          );
      });
  }
  function handleGetPWD() {
    const building = _buildingRef.current.value.trim();
    const room = _roomRef.current.value.trim();
    if (building === "" || room === "") return;
    return fetch(APIURLs.clients.getPWD, {
      building,
      room,
    })
      .then((resp) => {
        _inputRef.current.value = resp.data.password.join(", ");
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setEdit(true);
          _inputRef.current.focus();
        }
      });
  }

  function handlClickDelete() {
    useMSGStore.getState().push(
      undefined,
      `삭제하시겠습니까?\n`,
      <Fragment>
        <Button onClick={handleCloseMSGDialog}>취소</Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleDelete}
          onError={() => console.log("DDDD")}
        >
          삭제
        </LoadingButton>
      </Fragment>,
      MSG_TYPE.WARN
    );
  }
  function handleClickAddField() {
    setAddFields((v) => [...v, <TextField name={`addedextra.${v.length}`} />]);
  }

  useEffect(() => {
    if (!data) {
      setEdit(false);
      setAddFields([]);
    }
  }, [data]);

  return (
    <StyledDialog open={!!data} onClose={onClose} fullWidth maxWidth="xs">
      {data && (
        <Fragment>
          {!isAdd && (
            <div className="actions">
              <Button
                variant="contained"
                color="error"
                onClick={handlClickDelete}
                endIcon={<DeleteIcon />}
              >
                삭제
              </Button>
            </div>
          )}
          <form onSubmit={handleSubmit} ref={_formRef}>
            <TextField
              name="building"
              inputRef={_buildingRef}
              label="동"
              defaultValue={data.building}
              disabled={!isAdd && !isEdit}
            />
            <TextField
              name="room"
              inputRef={_roomRef}
              label="호"
              defaultValue={data.room}
              disabled={!isAdd && !isEdit}
            />
            <div className="pwd">
              <TextField
                name="password"
                inputRef={_inputRef}
                fullWidth
                label="비밀번호"
                defaultValue={(data.password ?? []).join(", ")}
                disabled={!isEdit}
              />
              {isAdd && (
                <LoadingButton onClick={handleGetPWD}>
                  <SearchIcon />
                </LoadingButton>
              )}
            </div>
            <TextField
              name="name"
              label="성함"
              defaultValue={data.name}
              disabled={!isAdd && !isEdit}
            />
            <TextField
              name="phone"
              label="전화번호"
              defaultValue={data.phone}
              disabled={!isAdd && !isEdit}
            />
            {!!data.extra &&
              Object.entries(data.extra).map(([k, v]) => (
                <TextField
                  name={`extra.${k}`}
                  label={k}
                  defaultValue={v}
                  disabled={!isAdd && !isEdit}
                />
              ))}
            {addFields}
            {(isAdd || isEdit) && (
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleClickAddField}
              >
                필드 추가
              </Button>
            )}
            <button
              style={{ display: "none" }}
              type="submit"
              ref={_buttonRef}
            />
            {/* <Button variant="contained">검색</Button> */}
          </form>
          <Divider middle orientation="horizontal" />
          <div className="actions">
            <Button onClick={onClose}>취소</Button>
            {isAdd ? (
              <LoadingButton variant="contained" onClick={handleClickSubmit}>
                추가
              </LoadingButton>
            ) : isEdit ? (
              <LoadingButton variant="contained" onClick={handleClickSubmit}>
                업데이트
              </LoadingButton>
            ) : (
              <Button variant="contained" onClick={handleClickEdit}>
                수정
              </Button>
            )}
          </div>
        </Fragment>
      )}
    </StyledDialog>
  );
}

export default DetailDialog;
