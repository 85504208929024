import { CssBaseline, Dialog, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./Components/Private";
import LoginOrCreatePage from "./Pages/LoginOrCreate";
import RootPage from "./Pages/Root";
import DialogMsg from "./Components/DialogMsg";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#46fe8d" },
    secondary: { main: "#05f9f9" },
    error: { main: "#FE6D4D" },
    background: { default: "#36353C", paper: "#111112" },
    grey: {
      50: "#9F9CA2",
      100: "#36353C",
      200: "#36353C",
      300: "#36353C",
      400: "#1F1F22",
      500: "#1F1F22",
      600: "#1F1F22",
      700: "#111112",
      800: "#111112",
      900: "#111112",
    },
  },
  typography: {
    fontFamily: `"Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
  },
  components: {
    MuiPaper: {
      defaultProps: { elevation: 5 },
      styleOverrides: {
        root: {
          borderRadius: 14,
          backgroundImage: "unset",
        },
      },
    },
  },
});
function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <GlobalStyles styles={{}} /> */}
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route
              exact
              path="/:type(login|create)?"
              component={LoginOrCreatePage}
            />
            <PrivateRoute path="/:page/:subpage?">
              <RootPage />
            </PrivateRoute>
          </Switch>
        </Router>
        <DialogMsg />
      </ThemeProvider>

      {/* <ErrorDialogComponent /> */}
    </React.Fragment>
  );
}

export default App;
