import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  TextField,
  Typography,
  collapseClasses,
  listItemButtonClasses,
  styled,
} from "@mui/material";
import { Fragment, cloneElement, useMemo, useRef, useState } from "react";
import { Map as immuMap } from "immutable";
import { getColumns, importXLSX, sortExcel } from "../../Utils/xlsx";
import { APIURLs } from "../../Urls";
import { fetch } from "../../Utils/fetch";
import { usePageStore } from ".";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: "visible",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${collapseClasses.wrapperInner}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

function UploadClientListPage({
  isWizard = false,
  sx,
  children: _submitComponent,
  onEnd,
}) {
  const fileButtonRef = useRef();
  const contractors = useRef();
  const startIdx = useRef();
  const [openIdx, setOpenIdx] = useState(0);
  const [state, setState] = useState(
    immuMap({
      getData: false,
      columnsRow: undefined,
      columns: { building: 0, room: 0, name: 0, phone: 0, password: [0] },
      result: null,
    })
  );
  const [err, setErr] = useState(immuMap());
  const submitComponent = useMemo(() => {
    if (!!_submitComponent)
      return cloneElement(_submitComponent, { onClick: handleClickImport });
    return;
  }, [_submitComponent]);

  async function handleChangeFile(e) {
    if (await importXLSX(e, contractors)) setErr((v) => v.set("import", true));
    else setState((v) => v.set("getData", true));
  }
  function handleClickImportButton() {
    fileButtonRef.current.click();
  }

  async function handleSetColumnRow(e) {
    e.preventDefault();
    const { columnrowidx } = Object.fromEntries(new FormData(e.target));
    let val = getColumns(contractors, Number(columnrowidx));
    startIdx.current = Number(columnrowidx);
    setState((v) => v.set("columnsRow", val));
  }
  async function handleSetColumnMatch(e) {
    e.preventDefault();
    const columns = Object.fromEntries(new FormData(e.target));
    setState((v) =>
      v.set("result", sortExcel(contractors, startIdx.current, columns))
    );
  }
  function handleClickImport() {
    fetch(APIURLs.vars("post"), { key: "startlog", value: false })
      .then(() =>
        fetch(APIURLs.clients.upload, {
          buildings: state.get("result").buildings,
          clients: state.get("result").result,
        })
      )
      .then(() => {
        if (isWizard) usePageStore.getState().setNext();
        else onEnd(false);
      })
      .catch((err) => {
        console.log("error");
        if (!isWizard) onEnd(true);
      });
  }
  function handleClickListSubHeader(e) {
    const { idx } = e.currentTarget.dataset ?? {};
    console.log(e.currentTarget.dataset);
    if (idx === undefined) return;
    setOpenIdx(Number(idx));
  }

  return (
    <Fragment>
      <StyledPaper sx={sx}>
        <List>
          <ListItemButton
            onClick={handleClickListSubHeader}
            data-idx={0}
            sx={{ borderRadius: "14px 14px 0 0" }}
          >
            1. 파일업로드
          </ListItemButton>
          <Collapse in={openIdx === 0}>
            <ListItem>
              <Button variant="contained" onClick={handleClickImportButton}>
                파일 선택
              </Button>
              <input
                ref={fileButtonRef}
                type="file"
                onChange={handleChangeFile}
                style={{ display: "none" }}
              />
            </ListItem>
          </Collapse>
          <ListItemButton onClick={handleClickListSubHeader} data-idx={1}>
            2. 헤더 행 입력
          </ListItemButton>
          <Collapse in={openIdx === 1}>
            <ListItem component="form" onSubmit={handleSetColumnRow}>
              <TextField type="number" label="열" name="columnrowidx" />
              <Button
                type="submit"
                variant="contained"
                sx={{ height: 56, ml: 1 }}
              >
                적용
              </Button>
            </ListItem>
          </Collapse>
          <ListItemButton
            onClick={handleClickListSubHeader}
            data-idx={2}
            disabled={state.get("columnsRow") === undefined}
          >
            3. 열 매칭
          </ListItemButton>
          <Collapse in={openIdx === 2} mountOnEnter unmountOnExit>
            {state.get("columnsRow") !== undefined && (
              <ListItem component="form" onSubmit={handleSetColumnMatch}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Stack direction="column" spacing={1}>
                      <TextField
                        type="number"
                        label="동"
                        name="building"
                        defaultValue={
                          state
                            .get("columnsRow")
                            .find((t) => t.value.includes("동")).idx
                        }
                      />
                      <TextField
                        type="number"
                        label="호"
                        name="room"
                        defaultValue={
                          state
                            .get("columnsRow")
                            .find((t) => t.value.includes("호")).idx
                        }
                      />
                      <TextField
                        type="number"
                        label="이름"
                        name="name"
                        defaultValue={
                          state
                            .get("columnsRow")
                            .find((t) => t.value.includes("이름")).idx
                        }
                      />
                      <TextField
                        type="number"
                        label="전화번호"
                        name="phone"
                        defaultValue={
                          state
                            .get("columnsRow")
                            .find((t) => t.value.includes("전화")).idx
                        }
                      />
                      <TextField
                        type="text"
                        label="세대비번"
                        name="password"
                        defaultValue={state
                          .get("columnsRow")
                          .filter((t) => t.value.includes("비번"))
                          .map((v) => v.idx)
                          .join(",")}
                      />
                       <TextField
                        type="text"
                        label="추가정보"
                        name="extra"
                        placeholder="','로 구분"
                      />
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      {state.get("columnsRow").map(({ value, idx }) => (
                        <Typography>{`${value}, ${idx}`}</Typography>
                      ))}
                    </Stack>
                  </Stack>
                  <div>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ height: 56, ml: 1 }}
                    >
                      오류확인
                    </Button>
                  </div>
                </Stack>
              </ListItem>
            )}
          </Collapse>
          <ListItemButton
            onClick={handleClickListSubHeader}
            data-idx={3}
            disabled={!state.get("result")}
          >
            4. 오류 확인
          </ListItemButton>
          <Collapse in={openIdx === 3} mountOnEnter unmountOnExit>
            {state.get("result") && (
              <Fragment>
                <ListSubheader disableSticky>
                  <Typography variant="Button">{`성공 - 계약자 (${
                    state.get("result").result?.length ?? 0
                  })`}</Typography>
                </ListSubheader>
                <ListSubheader disableSticky>
                  <Typography variant="Button">{`오류 - 계약자 (${
                    state.get("result").error?.length ?? 0
                  })`}</Typography>
                </ListSubheader>
                <ListItem>
                  <List
                    dense
                    sx={{
                      width: "100%",
                      maxHeight: 300,
                      overflow: "hidden auto",
                    }}
                  >
                    {state.get("result").error?.map((v) => (
                      <ListItem disableGutters disablePadding>
                        <ListItemText
                          primary={`오류 내용 : ${v[1]}`}
                          secondary={`행 : ${v[0]}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              </Fragment>
            )}
          </Collapse>
        </List>
      </StyledPaper>
      {isWizard ? (
        <div className="action">
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: "grey.400" }}
            disabled
          >
            Prev
          </Button>
          <Button variant="contained" onClick={handleClickImport}>
            Next
          </Button>
        </div>
      ) : (
        submitComponent
      )}
    </Fragment>
  );
}

export default UploadClientListPage;
