import { Button, styled } from "@mui/material";
import { Fragment, useRef, useState } from "react";

const StyledImageStack = styled("div")(({ theme }) => ({
  display: "inline-flex",
  justifyContent: "flex-start",
  // width: "100%",
  overflow: "auto hidden",
  [`& > img`]: { height: 40, marginRight: theme.spacing(2) },
}));

function ImageButton({ name, src, limit = 1, disabled = false }) {
  const [images, setImages] = useState([src]);
  const inputRef = useRef();

  function handleOnPatchChangeImg(e) {
    setImages(
      [...e.target.files]
        .slice(0, limit)
        .map((file) => URL.createObjectURL(file))
    );
  }

  return (
    <Fragment>
      <Button component="label" variant="contained" disabled={disabled}>
        이미지 선택
        <input
          ref={inputRef}
          multiple={limit > 1}
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={handleOnPatchChangeImg}
          name={name}
        />
      </Button>
      <StyledImageStack>
        {images.map((_url) => (
          <img
            src={_url}
            alt="blank"
            // style={{ backgroundImage: `url(${_url})` }}
          />
        ))}
      </StyledImageStack>
    </Fragment>
  );
}

export default ImageButton;
