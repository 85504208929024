import {
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  TextareaAutosize,
  collapseClasses,
  listItemButtonClasses,
  styled,
} from "@mui/material";
import {
  Fragment,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { APIURLs } from "../../Urls";
import { fetch } from "../../Utils/fetch";
import { sms } from "../../Templates/sms";
import { useHistory } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: "visible",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${collapseClasses.wrapperInner}`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& textarea`]: {
    backgroundColor: theme.palette.grey[100],
    outline: "0px transparent !important",
    color: "#fff",
  },
}));

function SetSMSPage({
  isWizard = false,
  sx,
  children: _submitComponent,
  onEnd,
}) {
  const histroy = useHistory();
  const [loading, setLoad] = useState(true);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState(sms);

  const submitComponent = useMemo(() => {
    if (!!_submitComponent)
      return cloneElement(_submitComponent, { onClick: handleClickImport });
    return;
  }, [_submitComponent]);

  const ref = useRef();

  function handleClickImport(e) {
    e.preventDefault();
    setLoad(true);
    const _data = Object.fromEntries(new FormData(ref.current));

    fetch(APIURLs.vars("post"), {
      key: "pwdtemplate",
      value: _data.pwdtemplate,
    })
      .then(() => fetch(APIURLs.vars("post"), { key: "startlog", value: true }))
      .then(() => {
        if (isWizard) histroy.push("/dashboard");
        else onEnd(false);
      })
      .catch(() => {
        if (!isWizard) onEnd(true);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    fetch(APIURLs.vars("get", { key: "pwdtemplate" }))
      .then((resp) => setData(resp.data.value ?? sms))
      .catch()
      .finally(() => setLoadedData(true));
  }, []);

  return (
    <Fragment>
      <StyledPaper sx={sx}>
        <List
          dense
          disablePadding
          component="form"
          ref={ref}
          onSubmit={(e) => e.preventDefault()}
        >
          {loadedData ? (
            <Fragment>
              {/* <ListItemButton>1. 행사 차수</ListItemButton> */}
              <ListItem>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder="Minimum 3 rows"
                  style={{ width: "100%", maxWidth: 568 }}
                  defaultValue={data}
                  name="pwdtemplate"
                />
              </ListItem>
            </Fragment>
          ) : (
            <CircularProgress />
          )}
        </List>
      </StyledPaper>
      {isWizard ? (
        <div className="action">
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: "grey.400" }}
            disabled
          >
            Prev
          </Button>
          <Button variant="contained" onClick={handleClickImport}>
            Next
          </Button>
        </div>
      ) : (
        submitComponent
      )}
    </Fragment>
  );
}

export default SetSMSPage;
