import { styled, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { fetch } from "../../Utils/fetch";
import { APIURLs } from "../../Urls";

import LoginPage from "./Login";
import CreatePage from "./Create";
import { useAuthStore } from "../../Store/authStore";

const StyledDiv = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const StyledProgressbarWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  inset: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function LoginOrCreatePage() {
  const history = useHistory();
  const [status, setStatus] = useState(0);
  useEffect(() => {
    if (useAuthStore.getState().info !== undefined) {
      history.push("/dashboard")
      return;
    }
    fetch(APIURLs.auth.check_is_ready)
      .then((resp) => {
        const res = resp.data.ready;
        setStatus(res);
        if (res === 1) history.replace("/login");
        else if (res === 2) history.replace("/create");
      })
      .catch(() => setStatus(-1));
  }, []);
  return (
    <StyledDiv>
      {status === 0 ? (
        <StyledProgressbarWrapper>
          <LinearProgress />
        </StyledProgressbarWrapper>
      ) : (
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/create" component={CreatePage} />
        </Switch>
      )}
    </StyledDiv>
  );
}

export default LoginOrCreatePage;
