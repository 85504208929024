import {
  Button,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Switch,
  TextField,
  collapseClasses,
  listItemButtonClasses,
  styled,
} from "@mui/material";
import {
  Fragment,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Map as immuMap } from "immutable";
import { APIURLs } from "../../Urls";
import { fetch } from "../../Utils/fetch";
import { usePageStore } from ".";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  overflow: "visible",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${collapseClasses.wrapperInner}`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& textarea`]: {
    backgroundColor: theme.palette.grey[100],
    outline: "0px transparent !important",
    color: "#fff",
  },
}));

function EventVarsPage({
  isWizard = false,
  sx,
  children: _submitComponent,
  onEnd,
}) {
  const [loading, setLoad] = useState(true);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState(
    immuMap({ operationTime: [], day: 1, allow_access: false })
  );
  const { operationTime, day, allowAccess } = useMemo(
    () => ({
      operationTime: data.get("operationTime") ?? [],
      day: data.get("day") ?? 1,
      allowAccess: data.get("allow_access"),
    }),
    [data]
  );
  const submitComponent = useMemo(() => {
    if (!!_submitComponent)
      return cloneElement(_submitComponent, { onClick: handleClickImport });
    return;
  }, [_submitComponent]);
  //   console.log(operationTime, day);

  const ref = useRef();

  function handleClickImport(e) {
    e.preventDefault();
    setLoad(true);
    const _data = Object.fromEntries(new FormData(ref.current));
    Promise.all([
      fetch(APIURLs.vars("post"), {
        key: "allow_access",
        value: _data.allow_access === "on",
      }),
      fetch(APIURLs.vars("post"), {
        key: "day",
        value: Number(_data.day) - 1,
      }),
      fetch(APIURLs.vars("post"), {
        key: "operation_time",
        value: [
          ..._data.start_time.split(":").map((i) => Number(i)),
          ..._data.end_time.split(":").map((i) => Number(i)),
        ],
      }),
    ])
      .then(() => {
        if (isWizard) usePageStore.getState().setNext();
        else onEnd(false);
      })
      .catch(() => {
        if (!isWizard) onEnd(true);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    Promise.allSettled([
      fetch(APIURLs.vars("get", { key: "day" }))
        .then((resp) =>
          setData((v) => v.set("day", (resp.data.value ?? 0) + 1))
        )
        .catch(),
      fetch(APIURLs.vars("get", { key: "allow_access" }))
        .then((resp) =>
          setData((v) => v.set("allow_access", resp.data.value ?? false))
        )
        .catch(),
      fetch(APIURLs.vars("get", { key: "operation_time" }))
        .then((resp) => {
          const _val = resp.data.value ?? [9, 30, 17, 30];
          setData((v) =>
            v.set("operationTime", [
              `${(_val[0] ?? (_val[0] = 8)) >= 10 ? _val[0] : `0${_val[0]}`}:${
                (_val[1] ?? (_val[1] = 0)) >= 10 ? _val[1] : `0${_val[1]}`
              }`,
              `${(_val[2] ?? (_val[2] = 17)) >= 10 ? _val[2] : `0${_val[2]}`}:${
                (_val[3] ?? (_val[3] = 0)) >= 10 ? _val[3] : `0${_val[3]}`
              }`,
            ])
          );
        })
        .catch(),
    ]).finally(() => setLoadedData(true));
  }, []);

  return (
    <Fragment>
      <StyledPaper sx={sx}>
        <List
          dense
          disablePadding
          component="form"
          ref={ref}
          onSubmit={(e) => e.preventDefault()}
        >
          {loadedData ? (
            <Fragment>
              <ListItemButton>1. 앱 접근 허용</ListItemButton>
              <ListItem>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isWizard}
                      defaultChecked={allowAccess}
                      name="allow_access"
                      color="primary"
                    />
                  }
                  // label={`현재: ${allowAccess ? "허용됨" : "허용되지 않음"}`}
                />
              </ListItem>
              <ListItemButton>2. 행사 차수</ListItemButton>
              <ListItem>
                <TextField
                  type="number"
                  label="차수"
                  name="day"
                  defaultValue={day ?? 1}
                />
              </ListItem>
              <ListItemButton>3. 행사 운영 시간</ListItemButton>
              <ListItem>
                <TextField
                  type="time"
                  label="시작 시간"
                  name="start_time"
                  defaultValue={operationTime[0] ?? "8:00"}
                />
              </ListItem>
              <ListItem>
                <TextField
                  type="time"
                  label="종료 시간"
                  name="end_time"
                  defaultValue={operationTime[1] ?? "17:00"}
                />
              </ListItem>
            </Fragment>
          ) : (
            <CircularProgress />
          )}
        </List>
      </StyledPaper>
      {isWizard ? (
        <div className="action">
          <Button
            variant="contained"
            color="inherit"
            sx={{ backgroundColor: "grey.400" }}
            disabled
          >
            Prev
          </Button>
          <Button variant="contained" onClick={handleClickImport}>
            Next
          </Button>
        </div>
      ) : (
        submitComponent
      )}
    </Fragment>
  );
}

export default EventVarsPage;
