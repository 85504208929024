import {
  Button,
  Dialog,
  Divider,
  Paper,
  Typography,
  dividerClasses,
  paperClasses,
  styled,
  typographyClasses,
} from "@mui/material";
import { MSG_TYPE, useMSGStore } from "../Store/msgStore";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Fragment, useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 9999,
  [`& .${paperClasses.root}`]: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    [`& > div.header`]: {
      display: "inline-flex",
      alignItems: "center",
      [`& > .${typographyClasses.root}`]: {
        paddingLeft: theme.spacing(1),
      },
    },
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(2, 0),
    },
    [`& > div.actions`]: {
      marginTop: theme.spacing(3),
      display: "inline-flex",
      justifyContent: "flex-end",
    },
  },
}));

function DialogMsg() {
  const [_title, _msg, _actions, _type, _done] = useMSGStore((state) => [
    state.title,
    state.msg,
    state.actions,
    state.type,
    state.done,
  ]);

  const [[title, msg, actions, type], setState] = useState([
    _title,
    _msg,
    _actions,
    _type,
  ]);

  function handleTransitionEnd() {
    if (_type === undefined) setState([]);
  }
  useEffect(() => {
    // console.log(_;
    if (_type !== undefined) setState([_title, _msg, _actions, _type]);
    console.log("aa");
    return () => {};
  }, [_type]);

  return (
    <StyledDialog
      open={_type !== undefined}
      maxWidth="xs"
      fullWidth
      onClose={handleTransitionEnd}
      //   onTransitionEnd={handleTransitionEnd}
    >
      <div className="header">
        {type === MSG_TYPE.INFO ? (
          <Fragment>
            <InfoOutlinedIcon />
            <Typography variant="h5">{title ?? "안내"}</Typography>
          </Fragment>
        ) : type === MSG_TYPE.SUCCESS ? (
          <Fragment>
            <CheckCircleOutlineIcon />
            <Typography variant="h5">{title ?? "성공"}</Typography>
          </Fragment>
        ) : type === MSG_TYPE.WARN ? (
          <Fragment>
            <WarningAmberIcon />
            <Typography variant="h5">{title ?? "경고"}</Typography>
          </Fragment>
        ) : type === MSG_TYPE.ERROR ? (
          <Fragment>
            <CancelOutlinedIcon />
            <Typography variant="h5">{title ?? "오류"}</Typography>
          </Fragment>
        ) : (
          <Typography>{title ?? "메세지"}</Typography>
        )}
      </div>
      <Divider flexItem middle orientation="horizontal" />
      <Typography variant="body1">{msg}</Typography>
      <div className="actions">
        {actions ?? <Button onClick={_done}>확인</Button>}
      </div>
    </StyledDialog>
  );
}
export default DialogMsg;
