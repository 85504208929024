import {
  Button,
  Divider,
  ListItemButton,
  listItemButtonClasses,
  ListItemText,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { fetch } from "../../../../Utils/fetch";
import { APIURLs } from "../../../../Urls";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Map as immuMap } from "immutable";
import FilterIcon from "@mui/icons-material/FilterAlt";
import FilterDialog from "./FilterDialog";
import DetailDialog from "./DetailDialog";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  [`& .${listItemButtonClasses.root}`]: {
    borderTop: `1px solid #404040`,
    [`&.no`]: { backgroundColor: "#BF2828" },
    [`&.ing`]: { backgroundColor: "#2D8453" },
    [`&.cplt`]: { backgroundColor: "#606060" },
  },
}));

const STATUS_ENUM = ["입장", "출발", "전달"];
const CLASS_ENUM = ["no", "ing", "cplt"];
function ManagerPage() {
  const [data, setData] = useState(immuMap({ next: undefined, results: [] }));
  const [wsReady, setwsReady] = useState(false);
  const filters = useRef();

  const [openFiterDialog, setOpenFiterDialog] = useState(false);
  const [isFilteredList, setFilteredList] = useState(false);
  const [selectedClient, setSelectedClient] = useState(false);

  const _dataList = useMemo(() => data.get("results"), [data]);

  const _hasNext = useMemo(() => {
    const a = data.get("next");
    return a;
  }, [data]);

  const isItemLoaded = useCallback((index) => !!_dataList[index], [_dataList]);

  const Row = useMemo(
    () =>
      memo(
        function Row({ index, style }) {
          const data = _dataList[index];
          return (
            <ListItemButton
              style={style}
              onClick={handleOpenDetailDialog}
              data-idx={index}
              className={CLASS_ENUM[data.signed]}
              disabled={data.signed === 2}
            >
              <ListItemText
                primary={`${data.building}동 ${data.room}호 (${data.visit_type?"초방":"재방"})`}
                secondary={`${moment(`${data.created_date}`).format(
                  "MM/DD hh:mm:ss"
                )} - ${STATUS_ENUM[data.signed]}`}
                secondaryTypographyProps={{}}
              />
            </ListItemButton>
          );
        },
        () => true
      ),
    [_dataList]
  );

  function handleOpenFilterDialog() {
    setOpenFiterDialog(true);
  }
  function handleCloseFilterDialog() {
    setOpenFiterDialog(false);
  }
  function handleOpenDetailDialog(e) {
    const { idx = undefined } = e.currentTarget.dataset ?? {};

    setSelectedClient(idx === undefined ? {} : _dataList[idx]);
  }
  function handleCloseDetailDialog() {
    setSelectedClient(undefined);
  }
  function handleLoadmore() {
    if (_hasNext)
      fetch({ url: data.get("next"), method: "get", auth: true })
        .then((resp) => {
          const data = resp.data;
          setData((v) =>
            v
              .set("next", data.next)
              .update("results", (_arr) => _arr.concat(data.results ?? []))
          );
        })
        .catch();
  }

  function handleFilterList(_filters = {}) {
    filters.current = _filters;

    fetch(APIURLs.manager.list, _filters)
      .then((resp) => {
        const data = resp.data;
        setFilteredList(_filters);
        setData((v) =>
          v.set("next", data.next).set("results", data.results ?? [])
        );
      })
      .catch();
  }

  useEffect(() => {
    const ws = new WebSocket(APIURLs.manager.ws.url);
    ws.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      if (!!filters.current) {
        if (
          filters.current.buildings &&
          filters.current.buildings.indexOf(data.building) === -1
        ) {
          return;
        }
        // if (filters.current.signed && filters.current.signed !== data.signed) {
        //   return;
        // }
      }
      setData((v) =>
        v.update("results", (_arr) => {
          if (data.type === "new") {
            const d = [data, ..._arr];
            return d;
          }
          const idx = _arr.findIndex((i) => i.id === data.id);
          if (idx > -1) {
            _arr[idx].signed = data.signed;
            return [..._arr];
          }
          return _arr;
        })
      );
    };
    ws.onopen = () => {
      setwsReady(true);
    };
    return () => {
      setwsReady(false);
      ws.close();
    };
  }, []);

  useEffect(() => {
    if (wsReady) {
      if (!isFilteredList) {
        filters.current = undefined;
        fetch(APIURLs.manager.list)
          .then((resp) => {
            const data = resp.data;
            setData((v) =>
              v.set("next", data.next).set("results", data.results ?? [])
            );
          })
          .catch();
      }
    }
    return () => {};
  }, [isFilteredList, wsReady]);

  return (
    <Fragment>
      {/* <Paper
        sx={{
          margin: "0 8px",
          p: 1,
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <Stack flexDirection="row">
          <Button
            size="small"
            endIcon={<FilterIcon />}
            onClick={handleOpenFilterDialog}
          >
            필터
          </Button>
          {isFilteredList && (
            <Button
              variant="text"
              size="small"
              endIcon={<CloseIcon />}
              onClick={() => setFilteredList(undefined)}
            >
              필터 초기화
            </Button>
          )}
          <Divider flexItem orientation="vertical" />
        </Stack>
      </Paper> */}
      <StyledPaper>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={_dataList.length + (_hasNext ? 1 : 0)}
              loadMoreItems={handleLoadmore}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={_dataList.length}
                  itemSize={72}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </StyledPaper>
      <FilterDialog
        open={openFiterDialog}
        onClose={handleCloseFilterDialog}
        onApply={handleFilterList}
        data={isFilteredList}
      />
      <DetailDialog data={selectedClient} onClose={handleCloseDetailDialog} />
    </Fragment>
  );
}

export default ManagerPage;
