import {
  Button,
  Dialog,
  Typography,
  buttonClasses,
  dividerClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { fetch } from "../../../../Utils/fetch";
import { APIURLs } from "../../../../Urls";
import moment from "moment";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    padding: theme.spacing(1),
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 0),
    },
    [`& > form `]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 300,
      padding: theme.spacing(2),
      [`& > *`]: {
        marginBottom: theme.spacing(2),
      },
      [`& > div.pwd`]: {
        display: "inline-flex",
        [`& > .${buttonClasses.root}`]: {
          minWidth: "unset",
        },
      },
      [`& > .${buttonClasses.root}`]: {
        //   marginTop: theme.spacing(4),
      },
    },
    [`& > div.actions`]: {
      display: "inline-flex",
      justifyContent: "flex-end",
      [`& > .${buttonClasses.root}`]: {
        minWidth: "unset",
      },
    },
  },
}));

function DetailDialog({ data, onClose }) {
  const [_d, setd] = useState();
  function handleClickDe() {
    fetch(APIURLs.manager.update, { id: data.id, signed: 1 }).then(() =>
      onClose()
    );
  }
  function handleClickcp() {
    fetch(APIURLs.manager.update, { id: data.id, signed: 2 }).then(() =>
      onClose()
    );
  }
  useEffect(() => {
    if (!!data)
      fetch(APIURLs.manager.detail, { id: data.id }).then((resp) => {
        setd(resp.data);
      });
  }, [data]);

  return (
    <StyledDialog open={!!data} onClose={onClose} fullWidth maxWidth="xs">
      {data && (
        <Fragment>
          <Typography variant="h6">{`${data.building}동 ${data.room}호`}</Typography>
          <Typography variant="body1">{`최근방문: ${
            _d ? moment(_d.created_date).format("MM월 DD일 hh:mm:ss") : ""
          }`}</Typography>
          {_d &&
            (_d.latest_signed === 0 ? (
              <Button variant="contained" onClick={handleClickDe}>
                출발
              </Button>
            ) : (
              <Button variant="contained" onClick={handleClickcp}>
                전달
              </Button>
            ))}
        </Fragment>
      )}
    </StyledDialog>
  );
}

export default DetailDialog;
