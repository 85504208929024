import Axios from "axios";
import { useAuthStore } from "../Store/authStore";

export function fetch(
  {
    url,
    method = "get",
    auth = false,
    contentType = "application/json",
    lang = "ko-kr",
    gotoLoginPage = true,
    ...etcOptions
  },
  payload
) {
  const headers = auth
    ? {
        Authorization: `Token ${useAuthStore.getState().info?.Token ?? ""}`,
        "Content-Type": contentType,
        "Accept-Language": lang,
      }
    : {
        "Content-Type": contentType,
        "Accept-Language": lang,
      };
  return new Promise((resolve, reject) =>
    Axios(url, {
      method: method,
      headers: headers,
      data: payload,
      ...etcOptions,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          reject(err);
          useAuthStore.getState().setInfo(undefined);
          if (gotoLoginPage) window.location.replace("/");
          return;
        }
        reject(err);
      })
  );
}
