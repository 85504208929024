import {
  Button,
  Dialog,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
  buttonClasses,
  dividerClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment, useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 0),
    },
    [`& > form `]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 300,
      padding: theme.spacing(2),
      [`& > *`]: {
        marginBottom: theme.spacing(2),
      },
      [`& > .${buttonClasses.root}`]: {
        //   marginTop: theme.spacing(4),
      },
    },
  },
}));
function BuildingFields() {
  return (
    <Fragment>
      <TextField name="building" variant="filled" label="동" />
      <TextField name="room" variant="filled" label="호" />
    </Fragment>
  );
}
function ClientFields() {
  return (
    <Fragment>
      <TextField name="phone" variant="filled" label="전화번호" />
      <Typography variant="body1" color={grey[500]} textAlign="center">
        또는
      </Typography>
      <TextField name="name" variant="filled" label="성함" />
    </Fragment>
  );
}

function FilterDialog({ open, onClose, onApply }) {
  const [tabIdx, setTabIdx] = useState(0);
  //   const innerComponent = useMemo(()=>)
  function handleChangeTabs(_, nv) {
    setTabIdx(nv);
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = Object.fromEntries(
      [...new FormData(e.target)]
        .map(([k, v]) => [k, v.trim()])
        .filter(([_, v]) => v !== "")
    );
    onApply(data);
  }
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Tabs value={tabIdx} onChange={handleChangeTabs} variant="fullWidthF">
        <Tab label="동/호수" value={0} />
        <Tab label="계약자" value={1} />
      </Tabs>
      <Divider middle orientation="horizontal" />
      <form onSubmit={handleSubmit}>
        {tabIdx === 0 ? <BuildingFields /> : <ClientFields />}
        <Button variant="contained" type="submit">
          검색
        </Button>
      </form>
    </StyledDialog>
  );
}

export default FilterDialog;
