export const pwd = `<div>
<body1>
  {{building}}동 {{room}}호 <br />{{name}}고객님 방문을 환영합니다.
</body1>
</div>
<br />
<divider></divider><br />
<div>
<body1 --font-weight--="900">▶ 해당 세대 공동현관 동출입구</body1>
<body1>비밀번호 : <b>{{building_password}}</b></body1>
</div>
<br />
<div>
<body1 --font-weight--="900">▶ 해당 세대 현관문</body1>
<body1>
  - 도어락 터치 후, 아래 비밀번호 입력<br />
  비밀번호 :<b>{{room_password}}*</b>(별표)
</body1>
</div>
<br />
<div>
<body1>
  ※ 동출입구 및 세대 현관문 비밀번호가 유출되지 않도록 유의해주시기
  바랍니다.<br />
  ※ 재방문 시 반드시 행사 본부에서 비밀번호를 재안내 받으시기 바랍니다.<br />
  (행사기간 비밀번호 변경)
</body1>
</div>
<br />
<divider></divider><br />
<div>
<body1 --font-weight--="900">▶ 행사 안내 주요 링크</body1>
<body1>1. 단지 가이드 맵</body1>
<go href="https://naver.me/5Dj15bZU">https://naver.me/5Dj15bZU</go>
<body1>2. 모바일 하자관리 서비스 이용 방법 </body1>
<go href="https://youtu.be/KrFxIrvpbxs">https://youtu.be/KrFxIrvpbxs</go>
<body1>3. 모바일 하자관리서비스 바로가기 </body1>
<go href="https://mobileas.dlenc.co.kr">https://mobileas.dlenc.co.kr</go>
</div>
<br />
<divider></divider><br />
<div>
<body1 --font-weight--="900">▶ 문의 : CS센터 {{contact}}</body1>
<body1>감사합니다.</body1>
</div>
`;
