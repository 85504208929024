import {
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  paperClasses,
  styled,
  buttonClasses,
} from "@mui/material";
import UploadClientListPage from "./UploadClientList";
import SetApartmentInfoPage from "./SetApartmentInfo";
import SetUIPage from "./SetUI";
import EventVarsPage from "./eventVars";
import SetSMSPage from "./SetSMS";
import { create } from "zustand";
export const usePageStore = create((set) => ({
  idx: 0,
  setNext: () => set((v) => ({ idx: v.idx + 1 })),
  setPrev: () => set((v) => ({ idx: v.idx - 1 })),
}));
const StyledWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  [`& > div.header`]: {
    // padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    [`& > .${paperClasses.root}`]: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      [`&.fit`]: {
        flex: 1,
      },
    },
  },
  [`& > div.action`]: {
    display: "inline-flex",
    [`& > .${buttonClasses.root}`]: {
      margin: theme.spacing(1),
      flex: 1,
    },
  },
  [theme.breakpoints.down("sm")]: {
    [`& > div.header`]: {
      flexDirection: "column",

      [` > .${paperClasses.root}`]: {
        flex: 1,
      },
    },
  },
}));
function WizradPage() {
  const currentStep = usePageStore((state) => state.idx);
  return (
    <StyledWrapper>
      <div className="header">
        <Paper>
          {/* <Typography variant="h4">Wizard</Typography> */}
          <ListItemText primary="Wizard" secondary="ECOLE-서비스 관리 시스템" />
        </Paper>
        <Paper className="fit">
          <Stepper
            activeStep={currentStep}
            alternativeLabel
            sx={{ width: "100%" }}
          >
            {[
              "계약자 업로드",
              "아파트 정보입력",
              "UI 설정",
              "이벤트 설정",
              "SMS 설정",
            ].map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </div>
      {currentStep === 0 ? (
        <UploadClientListPage isWizard />
      ) : currentStep === 1 ? (
        <SetApartmentInfoPage isWizard />
      ) : currentStep === 2 ? (
        <SetUIPage isWizard />
      ) : currentStep === 3 ? (
        <EventVarsPage isWizard />
      ) : currentStep === 4 ? (
        <SetSMSPage isWizard />
      ) : (
        <div />
      )}
    </StyledWrapper>
  );
}
export default WizradPage;
