import {
  Button,
  Divider,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";
import { fetch } from "../../../../Utils/fetch";
import { APIURLs } from "../../../../Urls";
import DownloadIcon from "@mui/icons-material/Download";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Map as immuMap } from "immutable";
import SearchIcon from "@mui/icons-material/Search";
import FilterDialog from "./FilterDialog";
import DetailDialog from "./DetailDialog";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { exportXLSX } from "../../../../Utils/xlsx";

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  margin: theme.spacing(1),
  padding: theme.spacing(2),
}));

// const isItemLoaded = (index) => !!itemStatusMap[index];
// const loadMoreItems = (startIndex, stopIndex) => {
//   for (let index = startIndex; index <= stopIndex; index++) {
//     itemStatusMap[index] = LOADING;
//   }
//   return new Promise((resolve) =>
//     setTimeout(() => {
//       for (let index = startIndex; index <= stopIndex; index++) {
//         itemStatusMap[index] = LOADED;
//       }
//       resolve();
//     }, 2500)
//   );
// };

// class Row extends PureComponent {
//   render() {
//     const { index, style } = this.props;
//     let label;
//     if (itemStatusMap[index] === LOADED) {
//       label = `Row ${index}`;
//     } else {
//       label = "Loading...";
//     }
//     return (
//       <div className="ListItem" style={style}>
//         {label}
//       </div>
//     );
//   }
// }

function ClientsPage() {
  const histroy = useHistory();
  const [loading, setLoad] = useState(true);

  const [loadedData, setLoadedData] = useState(false);
  const [data, setData] = useState(immuMap({ next: undefined, results: [] }));

  const [openFiterDialog, setOpenFiterDialog] = useState(false);
  const [isFilteredList, setFilteredList] = useState(false);
  const [selectedClient, setSelectedClient] = useState(false);

  const _dataList = useMemo(() => data.get("results"), [data]);
  const _hasNext = useMemo(() => {
    const a = data.get("next");
    console.log(a);
    return a;
  }, [data]);
  const isItemLoaded = useCallback((index) => !!_dataList[index], [_dataList]);
  const Row = useMemo(
    () =>
      memo(
        function Row({ index, style }) {
          const data = _dataList[index];
          return (
            <ListItemButton
              style={style}
              onClick={handleOpenDetailDialog}
              data-idx={index}
            >
              <ListItemText
                primary={`${data.building}동 ${data.room}호`}
                secondary={`${data.name} - ${data.phone}`}
              />
            </ListItemButton>
          );
        },
        () => true
      ),
    [_dataList]
  );

  function handleOpenFilterDialog() {
    setOpenFiterDialog(true);
  }
  function handleCloseFilterDialog() {
    setOpenFiterDialog(false);
  }
  function handleOpenDetailDialog(e) {
    const { idx = undefined } = e.currentTarget.dataset ?? {};

    setSelectedClient(idx === undefined ? {} : _dataList[idx]);
  }
  function handleCloseDetailDialog() {
    setSelectedClient(undefined);
  }
  function handleLoadmore() {
    if (_hasNext)
      fetch({ url: data.get("next"), method: "get", auth: true })
        .then((resp) => {
          const data = resp.data;
          setData((v) =>
            v
              .set("next", data.next)
              .update("results", (_arr) => _arr.concat(data.results ?? []))
          );
        })
        .catch()
        .finally(() => setLoadedData(true));
  }

  function handleFilterList(filters = {}) {
    fetch(APIURLs.clients.search(filters))
      .then((resp) => {
        const data = resp.data;
        setFilteredList(true);
        setData((v) =>
          v.set("next", data.next).set("results", data.results ?? [])
        );
      })
      .catch()
      .finally(() => setLoadedData(true));
  }
  async function handleClickExport() {
    const building_info = await fetch(
      APIURLs.vars("get", { key: "building_info" })
    ).then((resp) => resp.data.value);

    await fetch(APIURLs.clients.export)
      .then((resp) => {
        const _data = resp.data;
        const pwdLength = _data[0].password;
        const result = new Array(_data.length + 1);
        result[0] = [
          "Building.(동)",
          "Room.(호)",
          "Name.(이름)",
          "Phone.(전화번호)",
          ...pwdLength.map((_, i) => `${i + 1}일차 세대비번`),
          ...pwdLength.map((_, i) => `${i + 1}일차 공동현관비번`),
        ];
        let _item;
        for (let i = 0; i < result.length - 1; i++) {
          _item = _data[i];
          // console.log(_item);
          result[i + 1] = [
            _item.building,
            _item.room,
            _item.name,
            _item.phone,
            ..._item.password,
            ...(building_info[`${_item.building}-${_item.room.at(-1)}`]
              ?.building_password ?? []),
          ];
        }
        return exportXLSX(result);
      })
      .then((blob) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.xlsx";
        link.click();
        link.remove();
      });
  }

  useEffect(() => {
    if (!isFilteredList)
      fetch(APIURLs.clients.search({}))
        .then((resp) => {
          const data = resp.data;
          setData((v) =>
            v
              .set("next", data.next)
              .update("results", (_arr) => _arr.concat(data.results ?? []))
          );
        })
        .catch()
        .finally(() => setLoadedData(true));
  }, [isFilteredList]);

  return (
    <Fragment>
      <Paper
        sx={{
          margin: "0 8px",
          p: 1,
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <Stack flexDirection="row">
          <Button
            size="small"
            endIcon={<SearchIcon />}
            onClick={handleOpenFilterDialog}
          >
            검색
          </Button>
          {isFilteredList && (
            <Button
              variant="text"
              size="small"
              endIcon={<CloseIcon />}
              onClick={() => setFilteredList(false)}
            >
              필터 초기화
            </Button>
          )}
          <Divider flexItem orientation="vertical" />
          <Button
            size="small"
            endIcon={<AddIcon />}
            onClick={handleOpenDetailDialog}
          >
            추가
          </Button>
        </Stack>
        <Button
          variant="outlined"
          size="small"
          endIcon={<DownloadIcon />}
          onClick={handleClickExport}
        >
          XLSX
        </Button>
      </Paper>
      <StyledPaper>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={_dataList.length + (_hasNext ? 1 : 0)}
              loadMoreItems={handleLoadmore}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={_dataList.length}
                  itemSize={72}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </StyledPaper>
      <FilterDialog
        open={openFiterDialog}
        onClose={handleCloseFilterDialog}
        onApply={handleFilterList}
      />
      <DetailDialog data={selectedClient} onClose={handleCloseDetailDialog} />
    </Fragment>
  );
}

export default ClientsPage;
