import {
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import ImageButton from "../../Components/ImageButton";
import { APIURLs } from "../../Urls";

export function GuideMapSubForm({
  data = {
    type: "image",
    size: "contained",
    downloadable: true,
    map_active: false,
  },
}) {
  const [type, setType] = useState(data.type ?? "image");
  const [active, setActive] = useState(data.map_active ?? false);
  return (
    <Fragment>
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          활성화
        </Typography>
        <Switch
          checked={active}
          onChange={(e, v) => setActive(v)}
          name="web_global_map_active"
          color="primary"
        />
      </ListItem>
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          타입
        </Typography>
        <Select
          name="guide_map-type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          disabled={!active}
        >
          <MenuItem value={"image"}>이미지</MenuItem>
          <MenuItem value={"video"}>유튜브</MenuItem>
        </Select>
      </ListItem>
      <ListItem component="div">
        <Typography variant="button" sx={{ pr: 2 }}>
          {type === "image" ? "이미지" : "Key"}
        </Typography>
        {type === "image" ? (
          <ImageButton
            disabled={!active}
            name="guide_map_image"
            src={APIURLs.files.get("guide").url}
          />
        ) : (
          <TextField name="guide_map-src" defaultValue={data.src} />
        )}
      </ListItem>
      {type === "image" && (
        <Fragment>
          <ListItem component="div">
            <Typography variant="button" sx={{ pr: 2 }}>
              사이즈
            </Typography>
            <Select
              name="guide_map-size"
              defaultValue={data.size}
              disabled={!active}
            >
              <MenuItem value={"contained"}>contained</MenuItem>
              <MenuItem value={"cover"}>cover</MenuItem>
            </Select>
          </ListItem>
          <ListItem component="div">
            <Typography variant="button" sx={{ pr: 2 }}>
              다운로드 가능 여부
            </Typography>
            <Select
              name="guide_map-downloadable"
              defaultValue={data.downloadable}
              disabled={!active}
            >
              <MenuItem value={true}>가능</MenuItem>
              <MenuItem value={false}>불가능</MenuItem>
            </Select>
          </ListItem>
        </Fragment>
      )}
    </Fragment>
  );
}
