import { Button, Paper, Tab, Tabs, styled } from "@mui/material";
import { Fragment, useMemo, useRef } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";

import UploadClientListPage from "../../../Wizard/UploadClientList";
import SetApartmentInfoPage from "../../../Wizard/SetApartmentInfo";
import SetUIPage from "../../../Wizard/SetUI";
import EventVarsPage from "../../../Wizard/eventVars";
import SetSMSPage from "../../../Wizard/SetSMS";
import { MSG_TYPE, useMSGStore } from "../../../../Store/msgStore";
import StaffListPage from "./StaffList";

const StyledPaper = styled(Paper)(({ theme }) => ({}));

function SettingsPage({ match }) {
  const _ref = useRef();
  const currentValue = useMemo(() => match.params?.subpage, [match]);

  function handleClickApply() {
    _ref.current?.click();
  }
  function handleEnd(isErr) {
    if (isErr)
      useMSGStore
        .getState()
        .push(
          undefined,
          `서버 오류\n(재시도 해주십시오)`,
          undefined,
          MSG_TYPE.ERROR
        );
    else
      useMSGStore
        .getState()
        .push(
          undefined,
          `성공적으로 저장하였습니다.`,
          undefined,
          MSG_TYPE.SUCCESS
        );
  }
  return (
    <Fragment>
      <Paper
        sx={{
          position: "sticky",
          top: 0,
          margin: "0 8px",
          p: 1,
          zIndex: 2,
          display: "inline-flex",
        }}
      >
        <Tabs variant="scrollable" sx={{ flex: 1 }} value={currentValue}>
          <Tab
            label="이벤트"
            LinkComponent={Link}
            value="events"
            to="/dashboard/settings/events"
          />
          <Tab
            label="UI"
            LinkComponent={Link}
            value="ui"
            to="/dashboard/settings/ui"
          />
          <Tab
            label="SMS"
            LinkComponent={Link}
            value="sms"
            to="/dashboard/settings/sms"
          />
          <Tab
            label="계약자"
            LinkComponent={Link}
            value="clients"
            to="/dashboard/settings/clients"
          />
          <Tab
            label="아파트"
            LinkComponent={Link}
            value="apt"
            to="/dashboard/settings/apt"
          />
          <Tab
            label="STAFF"
            LinkComponent={Link}
            value="staff"
            to="/dashboard/settings/staff"
          />
        </Tabs>
        <Button variant="contained" onClick={handleClickApply}>
          {currentValue === "staff" ? "추가" : "적용"}
        </Button>
      </Paper>

      <Switch>
        <Route exact path="/dashboard/settings/events">
          <EventVarsPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </EventVarsPage>
        </Route>
        <Route exact path="/dashboard/settings/ui">
          <SetUIPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </SetUIPage>
        </Route>
        <Route exact path="/dashboard/settings/sms">
          <SetSMSPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </SetSMSPage>
        </Route>
        <Route exact path="/dashboard/settings/clients">
          <UploadClientListPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </UploadClientListPage>
        </Route>
        <Route exact path="/dashboard/settings/apt">
          <SetApartmentInfoPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </SetApartmentInfoPage>
        </Route>
        <Route exact path="/dashboard/settings/staff">
          <StaffListPage
            sx={{
              flex: 1,
              // display: "flex",
              flexWrap: "wrap",
              m: 1,
              p: 2,
              overflow: "visible",
            }}
            onEnd={handleEnd}
          >
            <button style={{ display: "none" }} ref={_ref} />
          </StaffListPage>
        </Route>
        <Redirect to="/dashboard/settings/events" />
      </Switch>
    </Fragment>
  );
}

export default SettingsPage;
