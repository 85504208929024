import XlsxPopulate from "xlsx-populate";

export function importXLSX(e, dataRef) {
  const file = e.target.files[0];
  if (!file) return true;
  return XlsxPopulate.fromDataAsync(file)
    .then((data) => {
      dataRef.current = data;
      return false;
    })
    .catch(() => true);
  // return false;
}

export function getColumns(dataRef, idx) {
  const rows = dataRef.current.sheet(0)._rows;
  return rows[idx]._cells
    .filter((v) => v !== undefined)
    .map((el, idx) => ({ value: el._value ?? "", idx: idx + 1 }));
}

export function sortExcel(dataRef, start, columns) {
  const data = dataRef.current.sheet(0)._rows;
  const apt = new Map();
  const result = new Map();
  const err = [];
  const lastIdx = data.length;

  const {
    building: buildingIdx,
    room: roomIdx,
    name: nameIdx,
    phone: phoneIdx,
    password: passwordIdx,
    extra: extraIdx,
  } = columns;

  const pwdIdxs = passwordIdx.split(",").map((v) => Number(v.trim()));

  const _extra_keys = data[start]._cells.map((el, idx) =>
    (el?._value ?? `${idx}`).trim()
  );
  const extraIdxs = extraIdx
    .split(",")
    .map((v) => v.trim())
    .filter((v) => v !== "")
    .map((v) => {
      const a = Number(v);
      return [_extra_keys[a], a];
    });

  let clinetRow, clientKey, aptKey, _apt_pwd, apt_pwd;

  for (
    let i = start + 1, row = data[start + 1]._cells;
    i < lastIdx;
    row = data[++i]?._cells
  ) {
    if (!row) break;

    //고객정보 유효성 검사
    clinetRow = {
      building: `${row[buildingIdx]?._value ?? ""}`.trim(),
      room: `${row[roomIdx]?._value ?? ""}`.trim(),
      password: pwdIdxs
        .map((i) => `${row[i]?._value ?? ""}`.trim())
        .filter((v) => v !== ""),
      name: `${row[nameIdx]?._value ?? ""}`.trim(),
      phone: `${row[phoneIdx]?._value ?? ""}`.trim(),
      extra: Object.fromEntries(
        extraIdxs.map(([k, idx]) => [k, `${row[idx]?._value ?? ""}`.trim()])
      ),
    };

    if (
      clinetRow.building === "" ||
      clinetRow.room === "" ||
      clinetRow.password.length === 0 ||
      clinetRow.name === "" ||
      clinetRow.phone === "" ||
      clinetRow.phone.includes("-") ||
      !clinetRow.phone.startsWith("010") ||
      clinetRow.phone.length !== 11
    ) {
      err.push([
        i,
        `정보 오류 : 
${clinetRow.building === "" ? "동," : ""}
${clinetRow.room === "" ? "호," : ""}
${clinetRow.password.length === 0 ? "비밀번호," : ""}
${clinetRow.name === "" ? "이름," : ""}
${
  clinetRow.phone === "" ||
  clinetRow.phone.includes("-") ||
  !clinetRow.phone.startsWith("010") ||
  clinetRow.phone.length !== 11
    ? "전화번호,"
    : ""
}`,
      ]);
      continue;
    }

    if (
      result.has(
        (clientKey = `${clinetRow.building}/${clinetRow.room}/${clinetRow.name}/${clinetRow.phone}`)
      )
    ) {
      err.push([
        i,
        `중복됨 : 
${clinetRow.building}동 ${clinetRow.room}호
계약자 "${clinetRow.name}"`,
      ]);
      continue;
    }

    _apt_pwd = apt.get((aptKey = `${clinetRow.building}/${clinetRow.room}`));
    if (_apt_pwd) {
      apt_pwd = clinetRow.password.join(",");
      if (_apt_pwd !== apt_pwd) {
        err.push([
          i,
          `비밀번호 다름 : 
${clinetRow.building}동 ${clinetRow.room}호`,
        ]);
        continue;
      }
    }

    result.set(clientKey, clinetRow);
    apt.set(aptKey, clinetRow.password.join(","));
  }
  return {
    buildings: (function () {
      const res = Object.fromEntries(
        [
          ...new Set(
            [...result.values()].map((v) => `${v.building}-${v.room.at(-1)}`)
          ),
        ].map((v) => [
          `${v}`,
          {
            building_password: null,
            contact: null,
          },
        ])
      );
      res["0-1"] = {
        building_password: null,
        contact: null,
      };
      res["0-2"] = {
        building_password: null,
        contact: null,
      };
      res["0-3"] = {
        building_password: null,
        contact: null,
      };
      res["0-4"] = {
        building_password: null,
        contact: null,
      };
      return res;
    })(),
    result: [...result.values()],
    error: err,
  };
}

export async function exportXLSX(values) {
  const workbook = await XlsxPopulate.fromBlankAsync();
  workbook.sheet(0).cell("A1").value(values);
  return await workbook.outputAsync();
}
