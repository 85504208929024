import { create } from "zustand";
import { shallow } from "zustand/shallow";
export const MSG_TYPE = {
  INFO: 0,
  WARN: 1,
  ERROR: 2,
  SUCCESS: 3,
  DEFAULT: 4,
};

export const useMSGStore = create(
  (set) => ({
    title: undefined,
    msg: undefined,
    type: undefined,
    onClose: undefined,
    actions: undefined,
    push: (title, msg, actions, type = MSG_TYPE.DEFAULT, onClose) => {
      set({
        title,
        msg,
        actions,
        type,
        onClose,
      });
    },
    done: () => {
      set((state) => {
        state.onClose?.();
        return {
          title: undefined,
          msg: undefined,
          actions: undefined,
          type: undefined,
          onClose: undefined,
        };
      });
    },
  }),
  { equalityFn: shallow }
);
