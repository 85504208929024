import {
  Button,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  buttonClasses,
  dividerClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { MSG_TYPE, useMSGStore } from "../../../../Store/msgStore";
import { fetch } from "../../../../Utils/fetch";
import LoadingButton from "../../../../Components/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { APIURLs } from "../../../../Urls";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    padding: theme.spacing(1),
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 0),
    },
    [`& > form `]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 300,
      padding: theme.spacing(2),
      [`& > *`]: {
        marginBottom: theme.spacing(2),
      },
      [`& > div.pwd`]: {
        display: "inline-flex",
        [`& > .${buttonClasses.root}`]: {
          minWidth: "unset",
        },
      },
      [`& > .${buttonClasses.root}`]: {
        //   marginTop: theme.spacing(4),
      },
    },
    [`& > div.actions`]: {
      display: "inline-flex",
      justifyContent: "flex-end",
      [`& > .${buttonClasses.root}`]: {
        minWidth: "unset",
      },
    },
  },
}));

function DetailDialog({ data, onClose }) {
  const _buttonRef = useRef();
  const _formRef = useRef();
  const isAdd = useMemo(() => {
    return !data?.username;
  }, [data]);
  const [isEdit, setEdit] = useState(false);
  //   const innerComponent = useMemo(()=>)
  function handleClickSubmit() {
    _buttonRef.current.click();
  }
  function handleClickEdit() {
    setEdit(true);
  }
  function handleCloseMSGDialog() {
    useMSGStore.getState().done();
  }
  function handleDelete() {
    fetch(APIURLs.staff.delete(data.username))
      .then(() => {
        useMSGStore.getState().done();
        onClose();
      })
      .catch(() => {
        useMSGStore
          .getState()
          .push(
            undefined,
            `서버 오류\n(재시도 해주십시오)`,
            undefined,
            MSG_TYPE.ERROR
          );
      });
  }
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const _data = Object.fromEntries(new FormData(e.target));

    if (!isAdd && isEdit) {
      for (let [k, v] of Object.entries(_data)) {
        if (data[k] === v) delete _data[k];
      }
      _data.username = data.username;
    }
    return fetch(!isAdd ? APIURLs.staff.update : APIURLs.staff.create, _data)
      .then(() => {
        onClose();
      })
      .catch((e) => {
        if (e.response.status === 400) {
          useMSGStore
            .getState()
            .push(
              undefined,
              `아이디가 이미 존재하거나 값입력이 잘못되었습니다.`,
              undefined,
              MSG_TYPE.ERROR
            );
          return;
        }
        useMSGStore
          .getState()
          .push(
            undefined,
            `서버 오류\n(재시도 해주십시오)`,
            undefined,
            MSG_TYPE.ERROR
          );
      });
  }

  function handlClickDelete() {
    useMSGStore.getState().push(
      undefined,
      `삭제하시겠습니까?\n`,
      <Fragment>
        <Button onClick={handleCloseMSGDialog}>취소</Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleDelete}
          onError={() => console.log("DDDD")}
        >
          삭제
        </LoadingButton>
      </Fragment>,
      MSG_TYPE.WARN
    );
  }
  useEffect(() => {
    if (!data) setEdit(false);
  }, [data]);
  return (
    <StyledDialog open={!!data} onClose={onClose} fullWidth maxWidth="xs">
      {data && (
        <Fragment>
          {!isAdd && (
            <div className="actions">
              <Button
                variant="contained"
                color="error"
                onClick={handlClickDelete}
                endIcon={<DeleteIcon />}
              >
                삭제
              </Button>
            </div>
          )}
          <form onSubmit={handleSubmit} ref={_formRef}>
            {isAdd && <TextField name="username" label="아이디" />}
            <FormControl fullWidth>
              <InputLabel>계약자 권한</InputLabel>
              <Select
                name="contractor_permission"
                label="계약자 권한"
                defaultValue={data.contractor_permission}
                disabled={!isAdd && !isEdit}
              >
                <MenuItem value={10}>전체</MenuItem>
                <MenuItem value={20}>읽기/쓰기</MenuItem>
                <MenuItem value={30}>읽기</MenuItem>
                <MenuItem value={99}>없음</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>방문자 권한</InputLabel>
              <Select
                name="visitor_permission"
                label="방문자 권한"
                defaultValue={data.visitor_permission}
                disabled={!isAdd && !isEdit}
              >
                <MenuItem value={10}>전체</MenuItem>
                <MenuItem value={30}>읽기</MenuItem>
                <MenuItem value={40}>매니저(제한)</MenuItem>
                <MenuItem value={99}>없음</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>설정 권한</InputLabel>
              <Select
                name="settings_permission"
                label="설정 권한"
                defaultValue={data.settings_permission}
                disabled={!isAdd && !isEdit}
              >
                <MenuItem value={true}>있음</MenuItem>
                <MenuItem value={false}>없음</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>슈퍼 권한</InputLabel>
              <Select
                name="super_permission"
                label="슈퍼 권한"
                defaultValue={data.super_permission}
                disabled={!isAdd && !isEdit}
              >
                <MenuItem value={true}>있음</MenuItem>
                <MenuItem value={false}>없음</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="password"
              label="비밀번호"
              type="password"
              disabled={!isAdd && !isEdit}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={_buttonRef}
            />
          </form>
          <Divider middle orientation="horizontal" />
          <div className="actions">
            <Button onClick={onClose}>취소</Button>
            {isAdd ? (
              <LoadingButton variant="contained" onClick={handleClickSubmit}>
                추가
              </LoadingButton>
            ) : isEdit ? (
              <LoadingButton variant="contained" onClick={handleClickSubmit}>
                업데이트
              </LoadingButton>
            ) : (
              <Button variant="contained" onClick={handleClickEdit}>
                수정
              </Button>
            )}
          </div>
        </Fragment>
      )}
    </StyledDialog>
  );
}

export default DetailDialog;
