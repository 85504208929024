import {
  Button,
  Dialog,
  MenuItem,
  Select,
  TextField,
  buttonClasses,
  dividerClasses,
  paperClasses,
  styled,
} from "@mui/material";
import { useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${paperClasses.root}`]: {
    [`& > .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 0),
    },
    [`& > form `]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 300,
      padding: theme.spacing(2),
      [`& > *`]: {
        marginBottom: theme.spacing(2),
      },
      [`& > .${buttonClasses.root}`]: {
        //   marginTop: theme.spacing(4),
      },
    },
  },
}));

function FilterDialog({ open, onClose, onApply, data = {} }) {
  const [type, setType] = useState(data.status);

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = Object.fromEntries(
      [...new FormData(e.target)]
        .map(([k, v]) => [k, v.trim()])
        .filter(([_, v]) => v !== "" || !!v)
    );
    if (!!data.buildings)
      data.buildings = data.buildings.split(",").map((i) => i.trim());
    if (!!data.signed) data.signed = Number(data.signed);
    onApply(data);
  }
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <TextField
          name="buildings"
          variant="filled"
          label="동"
          defaultValue={data.buildings?.join(", ")}
        />
        <Select
          placeholder="상태"
          name="signed"
          value={type}
          variant="filled"
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={false}>-</MenuItem>
          <MenuItem value={0}>미전달</MenuItem>
          <MenuItem value={1}>전달</MenuItem>
          <MenuItem value={2}>완료</MenuItem>
        </Select>
        <Button variant="contained" type="submit">
          필터
        </Button>
      </form>
    </StyledDialog>
  );
}

export default FilterDialog;
