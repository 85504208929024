import {
  Button,
  Paper,
  Stack,
  TextField,
  colors,
  paperClasses,
  stackClasses,
  styled,
  textFieldClasses,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { APIURLs } from "../../Urls";
import { fetch } from "../../Utils/fetch";

const StyledDiv = styled("div")(({ theme }) => ({
  minWidth: 450,
  position: "absolute",
  left: theme.spacing(2),
  top: theme.spacing(2),
  bottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [`& > .${paperClasses.root}`]: {
    width: "100%",
    [`& > .${stackClasses.root}`]: {
      padding: theme.spacing(4),
      [`& > .${textFieldClasses.root}`]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    right: theme.spacing(2),
    minWidth: "unset",
  },
}));
function CreatePage() {
  const history = useHistory();
  const [err, setErr] = useState({
    id: undefined,
    pwd: undefined,
    key: undefined,
  });
  const [loading, setLoading] = useState(0);
  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const data = Object.fromEntries(new FormData(e.target));
    let err = {
      id:
        (data.username = data.username.trim()) === ""
          ? "값이 필요합니다."
          : undefined,
      pwd:
        (data.password = data.password.trim()) === ""
          ? "값이 필요합니다."
          : undefined,
      re_pwd:
        data.password !== data.chk_password.trim()
          ? "일치하지 않습니다."
          : undefined,
      key:
        (data.authorized_key = data.authorized_key.trim()) === ""
          ? "값이 필요합니다."
          : undefined,
    };
    setErr(err);

    if (Object.values(err).some((i) => i !== undefined)) {
      setLoading(-1);
      return;
    }

    setLoading(1);
    data.super_permission = true;

    fetch(APIURLs.auth.create_super_user, data)
      .then((resp) => {
        history.replace("/login");
      })
      .catch(({ response = { data: {} } }) => {
        setLoading(-1);
        let err = {
          id: response.data.username,
          pwd: response.data.password,
          re_pwd: undefined,
          key: response.data.authorized_key,
        };
        setErr(err);
      });
  }
  return (
    <StyledDiv>
      <Paper>
        <Stack flexDirection="column" component="form" onSubmit={handleSubmit}>
          <TextField
            variant="filled"
            size="small"
            name="username"
            label="ID"
            helperText={err.id}
          />
          <TextField
            variant="filled"
            size="small"
            name="password"
            label="PASSWORD"
            type="password"
            helperText={err.pwd}
          />
          <TextField
            variant="filled"
            size="small"
            name="chk_password"
            label="Check PASSWORD"
            type="password"
            helperText={err.re_pwd}
          />
          <TextField
            variant="filled"
            size="small"
            name="authorized_key"
            label="Authrozied Key"
            type="password"
            helperText={err.key}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={loading === 1}
            color={loading === -1 ? "error" : undefined}
          >
            Create
          </Button>
        </Stack>
      </Paper>
    </StyledDiv>
  );
}

export default CreatePage;
