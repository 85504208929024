import {
  Divider,
  Paper,
  Typography,
  dividerClasses,
  styled,
  typographyClasses,
} from "@mui/material";
import { Fragment } from "react";

const StyledDiv = styled(Paper)(({ theme }) => ({
  // width: "100%",
  width: 276,
  height: 340,
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    // flex: 1,
  },
  [`& > div.wrapper`]: {
    display: "flex",
    flexDirection: "row",
  },
  [`& div.container`]: {
    display: "inline-flex",
    flex: 1,
    margin: theme.spacing(2, 0),
    [`& > div.chart-wrapper`]: {
      height: "100%",
      width: 40,
      display: "flex",
      flexDirection: "column-reverse",
      backgroundColor: theme.palette.grey[400],
      borderRadius: 4,
      overflow: "visible",
      position: "relative",
      [`&:not(:first-child)`]: {
        marginLeft: theme.spacing(1),
      },
      [`& > div.chart1`]: {
        backgroundColor: theme.palette.primary.main,
      },
      [`& > div.chart2`]: {
        backgroundColor: `${theme.palette.primary.main}33`,
      },
      [`& > .${typographyClasses.caption}`]: {
        position: "absolute",
        overflow: "visible",
        top: -20,
        textWrap: "nowrap",
      },
    },
    [`& > div.chart-values`]: {
      height: "100%",
      width: 60,
      display: "flex",
      flexDirection: "column-reverse",
      // backgroundColor: theme.palette.grey[400],
      borderRadius: 4,
      overflow: "visible",
      position: "relative",
      [`&:not(:nth-child(2))`]: {
        width: 30,
      },
      [`& > div.value1`]: {
        borderTop: `1px solid ${theme.palette.grey[50]}`,
        color: theme.palette.grey[50],
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "relative",
        [`& > .${typographyClasses.caption}`]: {
          position: "absolute",
          top: 0,
          paddingLeft: 4,
          textWrap: "balance",
        },
      },
      [`& > div.value2`]: {
        borderTop: `1px solid ${theme.palette.grey[50]}`,
        color: theme.palette.grey[50],
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "relative",
        [`& > .${typographyClasses.caption}`]: {
          position: "absolute",
          top: -20,
          paddingLeft: 4,
          textWrap: "balance",
        },
      },
    },
  },
  [`& div.databox`]: {
    display: "inline-flex",
    [`& > div.typo`]: {
      display: "flex",
      flexDirection: "column",
    },
    [`& div.with-unit`]: {
      display: "inline-flex",
      alignItems: "baseline",
    },
    [`& .${typographyClasses.caption}`]: {
      color: theme.palette.grey[50],
    },
    [`& .${dividerClasses.root}`]: {
      margin: theme.spacing(1, 2),
    },
    [`&.vertical`]: {
      display: "flex",
      flexDirection: "column",
    },
    [`&.small`]: {
      [`& .${dividerClasses.root}`]: {
        margin: theme.spacing(0.5),
      },
    },
  },
}));
function Chart({ title, data }) {
  return (
    <Fragment>
      <div className="chart-wrapper">
        <Typography variant="caption">{title}</Typography>
        {data.map((v, idx) => (
          <div className={`chart${idx + 1}`} style={{ height: v.value }} />
        ))}
      </div>
      <div className="chart-values">
        {data.map((v, idx) => (
          <div className={`value${idx + 1}`} style={{ height: v.value }}>
            <Typography variant="caption">{`${
              v.value.replace("%", "").split(".")[0]
            }% ${v.name}`}</Typography>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default function VisitCard({ title, data }) {
  return (
    <StyledDiv>
      <div
        className="databox small"
        style={{ justifyContent: "space-between" }}
      >
        <Typography>
          {title}
          {title !== "전체" ? "동" : ""}
        </Typography>
        <Typography variant="caption">{`총 ${data.t} 세대`}</Typography>
      </div>
      <div className="wrapper">
        <div className="container">
          <Chart
            title="금일"
            data={[
              {
                name: "(초방)",
                value: `${(data.today_first_visit / data.t) * 100}%`,
              },
              {
                name: "(재방)",
                value: `${(data.today_re_visit / data.t) * 100}%`,
              },
            ]}
          />
        </div>
        <div className="databox vertical">
          <div className="typo">
            <Typography variant="caption">금일 초방</Typography>
            <div className="with-unit">
              <Typography variant="h4">{data.today_first_visit}</Typography>
              {/* <Typography variant="h4">0000</Typography> */}
              <Typography variant="caption">세대</Typography>
            </div>
          </div>
          <Divider flexItem orientation="vertical" middle />
          <div className="typo">
            <Typography variant="caption">금일 재방</Typography>
            <div className="with-unit">
              <Typography variant="h4">{data.today_re_visit}</Typography>
              {/* <Typography variant="h4">0000</Typography> */}
              <Typography variant="caption">세대</Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider flexItem orientation="horizontal" />
      <div
        className="databox small"
        style={{
          paddingTop: 8,
          marginBottom: -8,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="caption">전체 &nbsp;&nbsp;&nbsp;</Typography>
        <div className="databox vertical">
          <div className="typo">
            <Typography variant="caption">전체 초방</Typography>
            <div className="with-unit">
              <Typography variant="h6">{data.total_first_visit}</Typography>
              {/* <Typography variant="h6">0000</Typography> */}
              <Typography variant="caption">{`세대 (${
                Math.round((data.total_first_visit / data.t) * 1000) / 10
              }%)`}</Typography>
            </div>
          </div>
          <Divider flexItem orientation="vertical" middle />
          <div className="typo">
            <Typography variant="caption">전체 재방</Typography>
            <div className="with-unit">
              <Typography variant="h6">{data.total_re_visit}</Typography>
              {/* <Typography variant="h6">0000</Typography> */}
              <Typography variant="caption">{`세대 (${
                Math.round((data.total_re_visit / data.t) * 1000) / 10
              }%)`}</Typography>
            </div>
            <div className="with-unit">
              <Typography variant="h6">{data.total_re_visit_num}</Typography>
              {/* <Typography variant="h6">0000</Typography> */}
              <Typography variant="caption">{`건 (${
                Math.round((data.total_re_visit_num / data.t) * 1000) / 10
              }%)`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}
